<template>
    <div>
      <!-- modal -->
      <b-modal id="paymentFormModal" ref="paymentFormModal" centered size="xl" no-close-on-backdrop :title=" $t('payment.info')" hide-footer>
        <b-form-group :disabled="isTransfer == true">
        <validation-observer ref="simpleRules">
          <div class="custom-search d-flex justify-content-end mb-1">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                      {{ $t('others.ok_title') }}
                    </b-button>
                </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="col-lg-6">
              <b-form>
                  <validation-provider :name="$t('payment.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                      <b-form-group :label="$t('payment.agent')" label-for="payment-agent-input" :state="errors.length > 0 ? false : null">
                          <v-select size="sm" id="payment-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
                          </v-select>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              {{ errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>
              </b-form>
            </b-col>
            <b-col class="col-lg-3">
              <label for="payment-document-no-input">{{ $t('payment.document_no') }}</label>
              <b-form-input id="payment-document-no-input" v-model="documentNo" size="sm"  maxlength="100"/>
            </b-col>
            <b-col class="col-lg-3">
              <b-form-group>
                <label for="payment-document-date-input">{{ $t('payment.document_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="documentDate"></CustomDatePicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col :hidden="documentTypeSelect == 1" class="col-lg-4">
              <b-form-group>
                <label for="payment-instruction-date-input">{{ $t('payment.instruction_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="instructionDate"></CustomDatePicker>
              </b-form-group>
            </b-col>
            <b-col :hidden="documentTypeSelect == 1" class="col-lg-4">
              <b-form-group>
                <label for="payment-payment-date-input">{{ $t('payment.payment_date') }}</label>
                <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDate"></CustomDatePicker>
              </b-form-group>
            </b-col>
            <b-col :hidden="documentTypeSelect == 1" class="col-lg-4">
              <label for="payment-correspondent-bank-input">{{ $t('payment.correspondent_bank') }}</label>
              <b-form-input id="payment-correspondent-bank-input" v-model="correspondentBank" size="sm"  maxlength="100"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-6 mb-1">
              <label for="payment-document-type-input">{{ $t('payment.document_type') }}</label>
              <validation-provider :name="$t('payment.document_type')" :rules="{ excluded: 0 }" v-slot="{ errors }">
              <b-form-select size="sm" v-model="documentTypeSelect" value-field="id" text-field="name" :options="documentTypeOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col class="col-lg-6 mb-1">
              <validation-provider :name="$t('payment.bank')" :rules="{ excluded: 0 }" v-slot="{ errors }">
                <label for="payment-bank-input">{{ $t('payment.bank') }}</label>
                <b-form-select size="sm" :state="errors.length > 0 ? false : null" v-model="bankSelect" value-field="id" text-field="name" :options="bankOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-12">
              <label for="payment-note-input">{{ $t('payment.note') }}</label>
              <b-form-textarea id="payment-note-input" :placeholder=" $t('payment.note')" rows="3" v-model="note" />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-lg-3 mt-1 mb-1">
              <label for="payment-invoce-total-input">{{ $t('payment.invoce_total') }}</label>
              <b-form-input disabled id="payment-invoce-total-input" v-model="invoiceTotal" size="sm"  maxlength="100"/>
            </b-col>
            <b-col class="col-lg-3 mt-1 mb-1">
              <label for="payment-amount-input">{{ $t('payment.amount') }}</label>
              <cleave id="payment-amount-input" v-model="amount" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
            </b-col>
            <b-col class="col-lg-3 mt-1 mb-1">
              <label for="payment-cost-input">{{ $t('payment.cost') }}</label>
              <cleave id="payment-cost-input" v-model="cost" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
            </b-col>
            <b-col :hidden="documentTypeSelect == 4" class="col-lg-3 mt-1 mb-1">
              <label for="payment-commission-input">{{ $t('payment.commission') }}</label>
              <cleave id="payment-commission-input" v-model="commission" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form-group>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getPaymentList: {type: Function},
    },
    watch: {
      agentSelect(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clientName = newValue.clientName
          this.clientAccountingCode = newValue.clientAccountingCode
          var clientId = newValue.clientId
          if (this.id == null && this.id == undefined) {
            this.id = 0
          }
        }
      },
    },
    data() {
      return {
        id: 0,
        clientId: 0,
        clientAccountingId: 0,
        clientAccountingCode: 0,
        clientName: '',
        agentSelect: 0,
        agentOption: [],
        documentNo: '',
        documentTypeSelect: 0,
        documentTypeOption: [],
        documentDate: null,
        instructionDate: null,
        paymentDate: null,
        receiptNo: '',
        senderName: '',
        bankSelect: 0,
        bankOption: [],
        correspondentBank: '',
        note: '',
        amount: '',
        cost: '',
        commission: '',
        hiddenControl: true,
        invoiceTotal:0,
        isTransfer:false,
        cleaveOptions: {
          prefix: '',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 2
        },
      }
    },
    methods: {
      setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
                this.clientAccountingId = event.clientAccountingId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },
      showInfo(id) {  
        this.$refs['paymentFormModal'].show()
        this.id = id
        if (this.id == 0)
          this.hiddenControl = true
        else this.hiddenControl = false
        const data = {id: this.id}
        const controller = {name : 'LawPayment'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.documentTypeSelect = response.data.lawPayment.lawPaymentMethodTypeId
                this.documentTypeOption = response.data.lawPaymentMethodTypeList
                this.documentDate = response.data.lawPayment.documentDate
                this.documentNo = response.data.lawPayment.documentNo
                this.instructionDate = response.data.lawPayment.directiveDate
                this.paymentDate = response.data.lawPayment.paymentDate
                this.receiptNo = response.data.lawPayment.receiptNo
                this.note = response.data.lawPayment.note
                this.senderName = response.data.lawPayment.senderName
                this.bankSelect = response.data.lawPayment.bankId
                this.bankOption = response.data.lawBankList
                this.amount = response.data.lawPayment.total
                this.cost = response.data.lawPayment.cost
                this.commission = response.data.lawPayment.commission
                this.correspondentBank = response.data.lawPayment.clientBank
                this.isTransfer = response.data.lawPayment.isTransfer
                this.documentTypeOption.splice(0, 0, this.$nullSelected())
                this.bankOption.splice(0, 0, this.$nullSelected())
                this.clientAccountingId = response.data.lawPayment.clientAccountingId
                
                // this.$refs.paymentDetailList.getPaymentDetailList(this.id, this.clientAccountingId)
                if (response.data.lawPayment.clientAccountingId > 0) {
                  const clientAccountingData = {
                    clientAccountingId: response.data.lawPayment.clientAccountingId
                  }
                  const clientAccountingController = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                  }
                  const clientAccountingPayload = {
                    data: clientAccountingData,
                    controller: clientAccountingController
                  }
                  this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                    this.agentOption = clientAccountingResponse.data.clientList
                    this.agentSelect = clientAccountingResponse.data.clientList[0]
                    this.clientId = clientAccountingResponse.data.clientList[0].clientId
                  })
                } else {
                    this.agentOption = []
                    this.agentSelect = 0;
              }
            } else {
                this.$bvModal.hide('paymentFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, clientAccountingId: this.agentSelect.clientAccountingId, lawPaymentMethodTypeId: this.documentTypeSelect, documentDate: this.documentDate,
          documentNo: this.documentNo, directiveDate: this.instructionDate, paymentDate: this.paymentDate, receiptNo: this.receiptNo, note: this.note, senderName: this.senderName,
          bankId: this.bankSelect, total: this.amount, cost: this.cost, commission: this.commission, clientBank: this.correspondentBank}
        const controller = {name : 'LawPayment'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$store.dispatch('moduleAuth/save',payload).then((response) => {
              if(response.data.resultStatus == true){
                this.getPaymentList()
                this.$SaveAlert() 
                if (this.id == 0) {
                  this.id = response.data.id
                  this.hiddenControl = false
                }
              } 
              else {
                this.$WarningAlert(response.data.resultMessage)
              }
            //   this.$refs.paymentDetailList.getPaymentDetailList(this.id, this.clientAccountingId)
            })
          }
        })
      }
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  [dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
  }
  .datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
  }
  .cleaveStyle {
    max-height: 3em!important;
    height: 2.2em!important;
  }
  </style>
  