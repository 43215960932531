<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8 mb-1">
                <b-col>
                    <label for="matter-report-agent-name-input"> {{ $t('matterReport.agent_name') }} </label>
                    <b-form-input id="matter-report-agent-name-input" size="sm" v-model="clientName" v-on:keyup.enter="getMatterReportList()" :placeholder="$t('matterReport.agent_name')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="matter-report-start-date-input"> {{ $t('matterReport.start_date') }} </label>
                    <b-form-datepicker id="matter-report-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterReport.start_date')" size="sm" v-model="startDate" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
                <b-col>
                    <label for="matter-report-end-date-input"> {{ $t('matterReport.end_date') }} </label>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterReport.end_date')" size="sm" v-model="endDate" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button  style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getMatterReportList()" v-on:keyup.enter="getMatterReportList()">{{ $t('matterReport.list') }}</b-button>
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="matterReportTable" ref="matterReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'accountingNo'" class="text-nowrap">
                    {{ $t('matterReport.account_no') }}
                </span>
                <span v-else-if="props.column.field === 'clientName'" class="text-nowrap">
                    {{ $t('matterReport.name') }}
                </span>
                <span v-else-if="props.column.field === 'matterCount'" class="text-nowrap">
                    {{ $t('matterReport.case') }}
                </span>
                <span v-else-if="props.column.field === 'evidenceDetectionCount'" class="text-nowrap">
                    {{ $t('matterReport.detection_of_evidence') }}
                </span>
                <span v-else-if="props.column.field === 'consultingCount'" class="text-nowrap">
                    {{ $t('matterReport.consultancy') }}
                </span>
                <span v-else-if="props.column.field === 'stopCustomsCount'" class="text-nowrap">
                    {{ $t('matterReport.stop_duty') }}
                </span>
                <span v-else-if="props.column.field === 'customsRegistrationCount'" class="text-nowrap">
                    {{ $t('matterReport.duty_registration') }}
                </span>
                <span v-else-if="props.column.field === 'sumugglingCount'" class="text-nowrap">
                    {{ $t('matterReport.smuggling') }}
                </span>
                <span v-else-if="props.column.field === 'otherCount'" class="text-nowrap">
                    {{ $t('matterReport.others') }}
                </span>
                <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                    {{ $t('matterReport.total') }}
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getMatterReportList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                startDate: null,
                endDate: null,
                clientName: '',
                columns: [{
                        label: this.$t('matterReport.account_no'),
                        field: `accountingNo`,
                    },
                    {
                        label: this.$t('matterReport.name'),
                        field: `clientName`,
                    },
                    {
                        label: this.$t('matterReport.case'),
                        field: `matterCount`,
                    },
                    {
                        label: this.$t('matterReport.detection_of_evidence'),
                        field: `evidenceDetectionCount`,
                    },
                    {
                        label: this.$t('matterReport.consultancy'),
                        field: `consultingCount`,
                    },
                    {
                        label: this.$t('matterReport.stop_duty'),
                        field: `stopCustomsCount`,
                    },
                    {
                        label: this.$t('matterReport.duty_registration'),
                        field: `customsRegistrationCount`,
                    },
                    {
                        label: this.$t('matterReport.smuggling'),
                        field: `sumugglingCount`,
                    },
                    {
                        label: this.$t('matterReport.others'),
                        field: `otherCount`,
                    },
                    {
                        label: this.$t('matterReport.total'),
                        field: `total`,
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.getMatterReportList()
        },
        methods: {         
            getMatterReportList() {
                this.rows = []
                const controller = { name: 'LawReport', actionName: 'GetLawMatterReportList' }
                const data = { pageLength: this.pageLength, pageNo: this.pageNo, agent: this.clientName, startCreateDate: this.startDate, endCreateDate: this.endDate }
                const payload = { controller: controller, data : data }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.lawMatterReportList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("Ajan İşlem Raporu", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    