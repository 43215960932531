<template>
<div>
    <!-- modal -->
    <b-modal id="matterNoteFormModal" ref="matterNoteFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterNote.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-12">
                <label for="matter-note-input">{{ $t('matterNote.note') }}</label>
                <b-form-textarea size="sm" id="matter-note-input" v-model="note" rows="3" max-rows="4" maxlength="600" />
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterNoteList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }

    },
    data() {
        return {
            id: 0,
            note: '',
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['matterNoteFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.note = response.data.lawMatterNote.note
                } else {
                    this.$bvModal.hide('matterNoteFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                note: this.note,
                lawMatterId: this.lawMatterId
            }
            const controller = {
                name: 'LawMatterNote'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterNoteFormModal')
                    this.getMatterNoteList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
