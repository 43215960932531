<template>
  <div>
    <!-- modal -->
    <b-modal id="lawActivityFormModal" ref="lawActivityFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawActivity.info')" :cancel-title=" $t('others.cancel_title')"
      :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col class="col-lg-6">
            <b-form>
                <label for="law-activity-code-input">{{ $t('lawActivity.code') }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" :name=" $t('lawActivity.code')" rules="required" >
                    <b-form-input id="law-activity-code-input" size="sm" v-model="code" maxlength="50" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            </b-form>
          </b-col>
          <b-col class="col-lg-6">
            <b-form>
                <label for="law-activity-name-input">{{ $t('lawActivity.name') }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" :name=" $t('lawActivity.name')" rules="required" >
                    <b-form-input id="law-activity-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    getLawActivityList: {type: Function},
  },
  data() {
    return {
      id: 0,
      code: '',
      name: '',
    }
  },
  methods: {
    showInfo(id) {  
      this.$refs['lawActivityFormModal'].show()
      this.id = id
      const data = {id: this.id}
      const controller = {name : 'LawActivity'}
      const payload = {data: data, controller: controller}
      this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
          if(response.data.resultStatus == true){
              this.code = response.data.lawActivity.code
              this.name = response.data.lawActivity.name
          } else {
              this.$bvModal.hide('lawActivityFormModal')
              this.$WarningAlert(response.data.resultMessage)
          }  
      })  
    },
    addOrUpdate() {
      const data = {id: this.id, code: this.code, name: this.name}
      const controller = {name : 'LawActivity'}
      const payload = {data: data, controller: controller}
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('moduleAuth/save',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.$bvModal.hide('lawActivityFormModal')
                this.getLawActivityList()
                this.$SaveAlert()
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
          })
        }
      })
    },
  }
}
</script>
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}
[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}
.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}
[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem!important;
    margin-top: -1.5rem!important;
    background-color: #ea5455!important;
    border-radius: 0.358rem!important;
    left: 20px!important
}
</style>
