<template>
<div>
    <!-- modal -->
    <b-modal id="matterBudgetFormModal" ref="matterBudgetFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterBudget.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-budget-agreed-flat-fee-input">{{ $t('matterBudget.agreed_flat_fee') + ' (' + currency + ')'}}</label>
                    <b-form-group>
                        <b-form-input id="matter-budget-agreed-flat-fee-input" size="sm" v-model="agreedFlatFee" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-budget-foreign-plaintiff-bond-input">{{ $t('matterBudget.foreign_plaintiff_bond') + ' (TRY)'}}</label>
                    <b-form-group>
                        <b-form-input id="matter-budget-foreign-plaintiff-bond-input" size="sm" v-model="foreignPlaintiffBond" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-budget-bond-deposit-date-input">{{ $t('matterBudget.bond_deposit_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterBudget.bond_deposit_date')" size="sm" v-model="bondDepositDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-budget-bond-collected-date-input">{{ $t('matterBudget.bond_collected_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterBudget.bond_collected_date')" size="sm" v-model="bondCollectedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <label for="matter-budget-is-damages-claimed-input">{{ $t('matterBudget.is_damages_claimed') }}</label>
                <b-form-checkbox class="custom-control-success" name="matter-budget-is-damages-claimed-input" id="matter-budget-is-damages-claimed-input" v-model="isDamagesClaimed" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>

            <b-col v-if="isDamagesClaimed"  class="col-lg-4">
                <b-form>
                    <label for="matter-budget-amount-granted-input">{{ $t('matterBudget.amount_granted') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-budget-amount-granted-input" size="sm" v-model="amountGranted" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col v-if="isDamagesClaimed"  class="col-lg-4">
                <b-form>
                    <label for="matter-budget-amount-claimed-input">{{ $t('matterBudget.amount_claimed') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-budget-amount-claimed-input" size="sm" v-model="amountClaimed" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-lg-4">
                <label for="matter-budget-is-collected-input">{{ $t('matterBudget.is_collected') }}</label>
                <b-form-checkbox class="custom-control-success" name="matter-budget-is-collected-input" id="matter-budget-is-collected-input" v-model="isCollected" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col v-if="isCollected" class="col-lg-4">
                <b-form>
                    <label for="matter-budget-collection-date-input">{{ $t('matterBudget.collection_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterBudget.collection_date')" size="sm" v-model="collectionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterBudgetList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }

    },
    data() {
        return {
            id: 0,
            agreedFlatFee: '',
            foreignPlaintiffBond: '',
            bondDepositDate: null,
            bondCollectedDate: null,
            isDamagesClaimed: false,
            amountClaimed: '',
            amountGranted: '',
            isCollected: false,
            collectionDate: null,
            currency: ''
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['matterBudgetFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterBudget'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.agreedFlatFee = response.data.lawMatterBudget.agreedFlatFee
                    this.foreignPlaintiffBond = response.data.lawMatterBudget.foreignPlaintiffBond
                    this.bondDepositDate = response.data.lawMatterBudget.bondDepositDate
                    this.bondCollectedDate = response.data.lawMatterBudget.bondCollectedDate
                    this.isDamagesClaimed = response.data.lawMatterBudget.isDamagesClaimed
                    this.amountClaimed = response.data.lawMatterBudget.amountClaimed
                    this.amountGranted = response.data.lawMatterBudget.amountGranted
                    this.isCollected = response.data.lawMatterBudget.isCollected
                    this.collectionDate = response.data.lawMatterBudget.collectionDate
                    this.currency = response.data.lawMatterBudget.currency
                } else {
                    this.$bvModal.hide('matterBudgetFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                agreedFlatFee: this.agreedFlatFee,
                foreignPlaintiffBond: this.foreignPlaintiffBond,
                bondDepositDate: this.bondDepositDate,
                bondCollectedDate: this.bondCollectedDate,
                isDamagesClaimed: this.isDamagesClaimed,
                amountClaimed: this.amountClaimed,
                amountGranted: this.amountGranted,
                isCollected: this.isCollected,
                collectionDate: this.collectionDate,
                lawMatterId: this.lawMatterId
            }
            const controller = {
                name: 'LawMatterBudget'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterBudgetFormModal')
                    this.getMatterBudgetList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
