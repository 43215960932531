<template>
<div>
    <b-card>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-smuggling-opposite-input">{{ $t('matterSmuggling.opposite') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-opposite-input" v-model="opposite" maxlength="300" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-smuggling-notification-received-input">{{ $t('matterSmuggling.notification_received') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-notification-received-input" v-model="notificationReceived" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-smuggling-notification-no-input">{{ $t('matterSmuggling.notification_no') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-notification-no-input" v-model="notificationNo" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-smuggling-received-date-input">{{ $t('matterSmuggling.received_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterSmuggling.received_date')" size="sm" v-model="receivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-smuggling-office-input">{{ $t('matterSmuggling.office') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-office-input" v-model="office" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-smuggling-publication-no-input">{{ $t('matterSmuggling.publication_no') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-publication-no-input" v-model="publicationNo" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-smuggling-goods-quantity-input">{{ $t('matterSmuggling.goods_quantity') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-smuggling-goods-quantity-input" v-model="goodsQuantity" type="number" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-smuggling-goods-input">{{ $t('matterSmuggling.goods') }}</label>
                    <b-form-group>
                        <b-form-textarea id="matter-smuggling-goods-input" :placeholder=" $t('matterSmuggling.goods')" v-model="goods" rows="3" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-button style="float: right;" variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('matterSmuggling.save')  }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            opposite: '',
            notificationReceived: '',
            notificationNo: '',
            receivedDate: null,
            office: '',
            publicationNo: '',
            goodsQuantity: '',
            goods: ''
        }
    },
    methods: {
        showSmuggling(id) {
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterSmuggling'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.opposite = response.data.lawMatterSmuggling.opposite
                    this.notificationReceived = response.data.lawMatterSmuggling.notificationReceived
                    this.notificationNo = response.data.lawMatterSmuggling.notificationNo
                    this.receivedDate = response.data.lawMatterSmuggling.receivedDate
                    this.office = response.data.lawMatterSmuggling.office
                    this.publicationNo = response.data.lawMatterSmuggling.publicationNo
                    this.goodsQuantity = response.data.lawMatterSmuggling.goodsQuantity
                    this.goods = response.data.lawMatterSmuggling.goods
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                opposite: this.opposite,
                notificationReceived: this.notificationReceived,
                notificationNo: this.notificationNo,
                receivedDate: this.receivedDate,
                office: this.office,
                publicationNo: this.publicationNo,
                goodsQuantity: this.goodsQuantity,
                goods: this.goods,
                lawMatterId : this.lawMatterId    

            }
            const controller = {
                name: 'LawMatterSmuggling'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
