<template>
<div>
    <!-- modal -->
    <b-modal id="matterFirstInstanceCourtFormModal" ref="matterFirstInstanceCourtFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterFirstInstanceCourt.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-first-instance-court-decision-input">{{ $t('matterFirstInstanceCourt.decision') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-first-instance-court-decision-input" size="sm" v-model="lawDecisionSelect" value-field="id" text-field="name" :options="lawDecisionOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-first-instance-court-date-input">{{ $t('matterFirstInstanceCourt.date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterFirstInstanceCourt.date')" size="sm" v-model="date" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-first-instance-court-no-input">{{ $t('matterFirstInstanceCourt.no') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-first-instance-court-no-input" size="sm" v-model="no" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterFirstInstanceCourtList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId:0,
            lawDecisionSelect: 0,
            lawDecisionOption: [],
            date: null,
            no: ''
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['matterFirstInstanceCourtFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterFirstInstanceCourt'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawDecisionSelect = response.data.lawMatterFirstInstanceCourt.lawDecisionId
                    this.lawDecisionOption = response.data.lawDecisionList
                    this.date = response.data.lawMatterFirstInstanceCourt.date
                    this.no = response.data.lawMatterFirstInstanceCourt.no
                    this.lawDecisionOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('matterFirstInstanceCourtFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                lawDecisionId: this.lawDecisionSelect,
                date: this.date,
                no: this.no,
                lawMatterId : this.lawMatterId    

            }
            const controller = {
                name: 'LawMatterFirstInstanceCourt'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterFirstInstanceCourtFormModal')
                    this.getMatterFirstInstanceCourtList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
