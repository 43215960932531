<template>
<div>
    <payment-form :getPaymentList="getPaymentList" ref="paymentFormModal"></payment-form>
    <div class="custom-search">
        <b-row>
            <b-col class="col-md-12">
                <b-form>
                    <label for="payment-bank-input">{{ $t('payment.bank') }}</label>
                    <b-form-select id="payment-bank-input" size="sm" v-model="lawBankSelect" value-field="id" text-field="name" :options="lawBankOption" />
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-2 mt-1">
                <label for="payment-invoice-no-input">{{ $t('payment.invoice_no') }}</label>
                <b-form>
                    <b-form-input :placeholder="$t('payment.invoice_no')" size="sm" id="payment-invoice-no-input" v-model="invoiceNo" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-2 mt-1">
                <label for="payment-agent-name-input">{{ $t('payment.agent_name') }}</label>
                <b-form>
                    <b-form-input :placeholder="$t('payment.agent_name')" size="sm" id="payment-agent-name-input" v-model="agentName" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-2 mt-1">
                <label for="payment-account-no-input">{{ $t('payment.account_no') }}</label>
                <b-form>
                    <b-form-input :placeholder="$t('payment.account_no')" size="sm" id="payment-account-no-input" v-model="clientAccountingNo" maxlength="50" />
                </b-form>
            </b-col>
            <b-col class="col-md-2 mt-1">
                <b-form>
                    <label for="payment-currency-input">{{ $t('payment.currency') }}</label>
                    <b-form-select id="payment-currency-input" size="sm" v-model="currencySelect" value-field="id" text-field="name" :options="currencyOption" />
                </b-form>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <b-button style="float:right;" class="mr-1 mt-1" size="sm" variant="outline-primary" v-on:keyup.enter="getPaymentList()" @click="getPaymentList()">{{ $t('payment.list') }}</b-button>
                <b-button style="float:right;" class="mt-1 mr-1" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('payment.add') }}</b-button>
                <b-button style="float:right;" class="mt-1" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-lg-4">
                                <b-form>
                                    <label for="payment-payment-date-start-input">{{ $t('payment.payment_date_start') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDateStart"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="col-lg-4">
                                <b-form>
                                    <label for="payment-payment-date-end-input">{{ $t('payment.payment_date_end') }}</label>
                                    <CustomDatePicker :datePickerValidateOption="0" v-model="paymentDateEnd"></CustomDatePicker>
                                </b-form>
                            </b-col>
                            <b-col class="col-lg-4">
                                <label for="payment-reference-input">{{ $t('payment.reference') }}</label>
                                <b-form>
                                    <b-form-input @input="getPaymentList()" :placeholder="$t('payment.reference')" size="sm" id="payment-reference-input" v-model="reference" maxlength="50" />
                                </b-form>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table id="paymentTable" ref="paymentTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'paymentDate'" class="text-nowrap">
                {{ $t('payment.payment_date') }}
            </span>
            <span v-else-if="props.column.field === 'senderName'" class="text-nowrap">
                {{ $t('payment.sender_name') }}
            </span>
            <span v-else-if="props.column.field === 'documentNo'" class="text-nowrap">
                {{ $t('payment.document_no') }}
            </span>
            <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                {{ $t('payment.date') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccounting'" class="text-nowrap">
                {{ $t('payment.agent') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('payment.amount') }}
            </span>
            <span v-else-if="props.column.field === 'totalAmount'" class="text-nowrap">
                {{ $t('payment.total') }}
            </span>
            <span v-else-if="props.column.field === 'paidInvoiceNo'" class="text-nowrap">
                {{ $t('payment.paid_invoice_no') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'paymentDate'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatFn(props.row.paymentDate) }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.paymentMethodType }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'senderName'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.senderName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.clientBank }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'documentDate'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.documentNo }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ $formatFn(props.row.documentDate) }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'date'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ $formatFn(props.row.date) }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ $formatFn(props.row.instructionDate) }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'clientAccounting'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.clientAccounting }}</span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'totalAmount'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.total + props.row.cost + props.row.commission }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="font-size: small"><strong>{{ props.row.currency }}</strong></span>
                    </b-col>
                </b-row>
            </span>
            <span v-else-if="props.column.field === 'total'">
                <b-row>
                    <b-col class="font-size: xx-small">
                        <span>{{ props.row.total }}</span>
                    </b-col>
                </b-row>
            </span>
            <!-- Column: Process -->
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EditIcon" />
                    </b-button>

                    <!-- <b-button v-if="props.row.isTransfer == false" v-b-tooltip.html :title="$t('others.Transfer')" v-b-modal.modal-top variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="approvalInvoice(props.row.id)">
                  <feather-icon icon="CompassIcon" />
              </b-button> -->

                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select size="sm" v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getPaymentList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    data() {
        return {
            total: 0,
            pageLength: 10,
            dir: false,
            pageNo: 1,
            columns: [{
                    label: this.$t('payment.payment_date'),
                    field: `paymentDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('payment.sender_name'),
                    field: `senderName`,
                },
                {
                    label: this.$t('payment.document_no'),
                    field: `documentDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('payment.date'),
                    field: `date`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('payment.agent'),
                    field: `clientAccounting`,
                },
                {
                    label: this.$t('payment.amount'),
                    field: `total`,
                    type: 'number',
                },
                {
                    label: this.$t('payment.total'),
                    field: `totalAmount`,
                    sortable: false,
                },
                {
                    label: this.$t('payment.paid_invoice_no'),
                    field: `paidInvoiceNo`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '15em',
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: '',
            invoiceNo: '',
            agentName: '',
            clientAccountingNo: '',
            currencySelect: 0,
            currencyOption: [],
            reference: '',
            paymentDateStart: null,
            paymentDateEnd: null,
            lawBankSelect : 0,
            lawBankOption : [],
        }
    },
    mounted() {
        this.getPaymentList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawPayment'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getPaymentList()
                        } else {
                            this.$CantDeletePaidPayment()
                            this.getPaymentList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.paymentFormModal.showInfo(id)
        },
        getPaymentList() {
            this.rows = []
            const controller = {
                name: 'LawPayment'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                reference: this.reference,
                currencyId: this.currencySelect,
                invoiceNo: this.invoiceNo,
                agentName: this.agentName,
                clientAccountingNo: this.clientAccountingNo,
                paymentDateStart: this.paymentDateStart,
                paymentDateEnd: this.paymentDateEnd,
                lawBankId : this.lawBankSelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.paymentMethodTypeOption = response.data.lawPaymentMethodTypeList
                    this.currencyOption = response.data.currencyList
                    //this.jurisdictionOption = response.data.jurisdictionList
                    this.lawBankOption = response.data.lawBankList
                    response.data.lawPaymentList.forEach(element => {
                        this.rows.push(element)
                    })
                    this.paymentMethodTypeOption.splice(0, 0, this.$nullSelected()),
                        this.currencyOption.splice(0, 0, this.$nullSelected()),
                        //this.jurisdictionOption.splice(0, 0, this.$nullSelected())
                    this.lawBankOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        approvalInvoice(id) {
            this.$PrivateConfirm(this.$t("payment.confirm_title"), this.$t("payment.confirm_message"), "warning", this.$t("payment.confirm_button_text"), this.$t("payment.cancel_button_text")).then(result => {
                if (result.value) {
                    const data = {
                        paymentId: id,
                        employeId: localStorage.getItem('EmployeeId')
                    }
                    const controller = {
                        name: 'InvoiceStatement',
                        actionName: 'ControlAndSaveStatement'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getPaymentList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("Payment", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
    },
}
</script>
  
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
