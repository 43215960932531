<template>
<div>
    <dailywork-form :getDailyworkList="getDailyworkList" ref="dailyworkForm"></dailywork-form>
    <matter-form ref="lawMatterForm"></matter-form>
    <b-modal id="invoiceAddDailyworkFormModal" centered size="xl" no-close-on-backdrop ref="invoiceAddDailyworkFormModal" :title=" $t('invoiceAddDailywork.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.add')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <vue-good-table id="invoiceAddDailyworkTable" ref="invoiceAddDailyworkTable" :columns="columns" :rows="rows" :sort-options="{
            enabled: false,
          }" styleClass="vgt-table condensed tableSize" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
              enabled: true,
              perPage: pageLength
            }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Column: Table Column -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>

            <!-- Row: Table Row -->
            <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field === 'isSelected'">
                    <b-form-checkbox @change.native="changeChecked(props.row.id, $event.target)" text-field="name" class="demo-inline-spacing" />
                </span>

                <span v-else-if="props.column.field === 'price'">
                    <span>
                        {{ $formatMoney(props.row.price) }}
                    </span>
                </span>

                <span v-else-if="props.column.field === 'lawMatterRecordNo'" class="text-nowrap">
                    <b-badge variant="primary" style="cursor: pointer;" @click="showOperationForm(props.row)">
                        <feather-icon icon="MousePointerIcon" class="mr-25" />
                        <span>{{ props.row.lawMatterRecordNo }}</span>
                    </b-badge>
                </span>

                <!-- Column: Process -->
                <span v-else-if="props.column.field === 'process'">
                    <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="openDailyworkForm(props.row.id, props.row.moduleId, props.row.recordId,props.row.paymentTypeId)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                        </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getDailyworkList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        showInvoiceInfo: {
            type: Function
        }
    },
    data() {
        return {
            invoiceId: 0,
            clientAccountingId: 0,
            rows: [],
            total: 0,
            pageLength: 10,
            pageNo: 1,
            columns: [{
                    label: '',
                    field: `isSelected`,
                },
                {
                    label: this.$t('dailyworkReport.recordNo'),
                    field: `lawMatterRecordNo`,
                },
                {
                    label: this.$t('dailyworkReport.serviceCode'),
                    field: `lawServiceCode`,
                },
                {
                    label: this.$t('dailyworkReport.ownerClient'),
                    field: `ownerClient`,
                },
                {
                    label: this.$t('dailyworkReport.shortName'),
                    field: `shortName`,
                },
                {
                    label: this.$t('dailyworkReport.description'),
                    field: `description`,
                },
                {
                    label: this.$t('dailyworkReport.date'),
                    field: `recordDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('dailyworkReport.unitPrice'),
                    field: `unitPrice`,
                },
                {
                    label: this.$t('dailyworkReport.amount'),
                    field: `price`,
                },
                {
                    label: this.$t('dailyworkReport.currency'),
                    field: `currency`,
                },
                {
                    label: this.$t('dailyworkReport.employee'),
                    field: `employee`,
                },
                {
                    label: this.$t('dailyworkReport.billingStatus'),
                    field: `lawBillingStatus`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '5em'
                },
            ],
        }
    },
    methods: {
        openDailyworkForm(id, moduleId, recordId, paymentTypeId) {
            this.$refs.dailyworkForm.showInfo(id, moduleId, recordId, paymentTypeId)
        },
        showOperationForm(row) {
            this.$refs.lawMatterForm.showInfo(row.recordId)
        },
        showInfo(invoiceId, clientAccountingId) {
            this.$refs['invoiceAddDailyworkFormModal'].show()
            this.invoiceId = invoiceId
            this.clientAccountingId = clientAccountingId
            this.getDailyworkList()
        },
        getDailyworkList() {
            this.rows = []
            const controller = {
                name: 'LawDailywork',
                actionName: 'GetLawDailyworkReport'
            }
            const data = {
                invoiceClientAccountingId: this.clientAccountingId,
                employeeId: 0,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                lawBillingStatusId: 0,
            }
            const payload = {
                data: data,
                controller: controller
            }
            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.rows = response.data.lawDailyworkList
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide()
            })
        },
        changeChecked(id, target) {
            const status = target.checked

            if (status) {
                var agentControl = this.rows.filter(p => p.isSelected == true)

                if (agentControl.length > 0) {
                    var dailyworkControl = this.rows.find(p => p.id == id)

                    if (agentControl.filter(p => p.invoiceClientAccountingId == dailyworkControl.invoiceClientAccountingId).length == 0) {
                        this.$WarningAlert(this.$t('others.NotSameAgentWarning'))
                        target.checked = false;
                        return
                    }
                }
            }

            this.rows = this.rows.map((dailyWork) => {
                if (dailyWork.id === id) {
                    return {
                        ...dailyWork,
                        isSelected: status
                    };
                }
                return dailyWork;
            });
        },
        addOrUpdate() {
            let dailyworkList = this.rows.filter(i => i.isSelected === true);

            if (dailyworkList.length == 0) {
                this.$ErrorSaveAlert()
                return
            }

            const data = {
                employeeId: localStorage.getItem('EmployeeId'),
                lawInvoiceDetailList: dailyworkList,
                lawInvoiceDto: {
                    id: this.invoiceId
                }
            }

            const controller = {
                name: 'LawInvoice',
                actionName: 'SaveLawInvoiceDetailFromDailyworkList'
            }

            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.showInvoiceInfo(this.invoiceId)
                    this.$bvModal.hide('invoiceAddDailyworkFormModal')
                    this.$SaveAlert()
                } else {
                    this.$bvModal.hide('invoiceAddDailyworkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })

        },
    }
}
</script>

<style>
.tab-content>.active {
    display: block;
    width: 75em;
}
</style>
