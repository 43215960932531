
<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8">
                <b-col>
                    <label for="cost-accounting-matter-no-input"> {{ $t('costAccounting.matter_no') }} </label>
                    <b-form-input id="cost-accounting-matter-no-input" size="sm" v-model="matterNo" v-on:keyup.enter="getCostAccountingList()" :placeholder="$t('costAccounting.matter_no')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="cost-accounting-vendor-input"> {{ $t('costAccounting.vendor') }} </label>
                    <b-form-select id="cost-accounting-vendor-input" size="sm" v-model="vendorSelect" value-field="id" text-field="name" :options="vendorOption" />
                </b-col>
                <b-col>
                    <label for="cost-accounting-expense-input"> {{ $t('costAccounting.expense') }} </label>
                    <b-form-input id="cost-accounting-expense-input" size="sm" v-model="expense" v-on:keyup.enter="getCostAccountingList()" :placeholder="$t('costAccounting.expense')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <b-card-text class="mb-0" for="cost-accounting-posted-input"> {{ $t('costAccounting.posted') }} </b-card-text>
                    <b-form-checkbox class="custom-control-success" id="cost-accounting-posted-input" v-model="posted" switch>
                      <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getSelfEmploymentInfo()" v-on:keyup.enter="getSelfEmploymentInfo()">{{ $t('costAccounting.list') }}</b-button>
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getSelfEmploymentInfo(0)">{{ $t('costAccounting.self_employment') }}</b-button> 
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-collapse class="mb-1" accordion type="margin">
                    <app-collapse-item :title="$t('others.search')">
                        <b-row>
                            <b-col class="col-lg-3">
                                <label for="cost-accounting-start-amount-input"> {{ $t('costAccounting.start_amount') }} </label>
                                <b-form-input id="cost-accounting-start-amount-input" size="sm" v-model="startAmount" v-on:keyup.enter="getCostAccountingList()" :placeholder="$t('costAccounting.start_amount')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="cost-accounting-end-amount-input"> {{ $t('costAccounting.end_amount') }} </label>
                                <b-form-input id="cost-accounting-end-amount-input" size="sm" v-model="endAmount" v-on:keyup.enter="getCostAccountingList()" :placeholder="$t('costAccounting.end_amount')" type="text" class="d-inline-block" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="cost-accounting-start-document-date-input"> {{ $t('costAccounting.start_document_date') }} </label>
                                <b-form-datepicker id="cost-accounting-start-document-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('costAccounting.start_document_date')" size="sm" v-model="startDocumentDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                            <b-col class="col-lg-3">
                                <label for="cost-accounting-end-document-date-input"> {{ $t('costAccounting.end_document_date') }} </label>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('costAccounting.end_document_date')" size="sm" v-model="endDocumentDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="costAccountingTable" ref="costAccountingTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'matterNo'" class="text-nowrap">
                    {{ $t('costAccounting.matter_no') }}
                </span>
                <span v-else-if="props.column.field === 'taskExpense'" class="text-nowrap">
                    {{ $t('costAccounting.task_expense') }}
                </span>
                <span v-else-if="props.column.field === 'agentClientName'" class="text-nowrap">
                    {{ $t('costAccounting.agent_client_name') }}
                </span>
                <span v-else-if="props.column.field === 'documentDate'" class="text-nowrap">
                    {{ $t('costAccounting.document_date') }}
                </span>
                <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                    {{ $t('costAccounting.user_name') }}
                </span>
                <span v-else-if="props.column.field === 'amountWitholdingTax'" class="text-nowrap">
                    {{ $t('costAccounting.amount_witholding_tax') }}
                </span>
                <span v-else-if="props.column.field === 'vatAmount'" class="text-nowrap">
                    {{ $t('costAccounting.vat_amount') }}
                </span>
                <span v-else-if="props.column.field === 'accountingDescription'" class="text-nowrap">
                    {{ $t('costAccounting.accounting_description') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>

            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'taskExpense'">
                    <span>
                        {{ props.row.task }}
                    </span>
                    <br>
                    <span style="font-size: smaller;">
                        {{ props.row.expense }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'agentClientName'">
                    <span>
                        {{props.row.agentName }}
                    </span>
                    <span style="font-size: smaller;">
                        {{props.row.clientName }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'documentDate'">
                    <span>
                        {{props.row.documentDate }}
                    </span>
                    <span style="font-size: smaller;">
                        {{props.row.date }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'amountWitholdingTax'">
                    <span>
                        {{props.row.amount }}
                    </span>
                    <span style="font-size: smaller;">
                        {{props.row.witholdingTax }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.dailywork')" v-b-modal.modal-top variant="secondary"
                          class="btn-icon mb-1 ml-1" size="sm" @click="getDailyworkInfo(props.row.id)">
                          <feather-icon icon="BriefcaseIcon" />
                        </b-button>
                        <b-button v-b-tooltip.html :title="$t('others.send_account')" variant="light" class="btn-icon mb-1 ml-1"
                          size="sm" @click="getSendAccountInfo(props.row.id)">
                          <feather-icon icon="SendIcon" />
                        </b-button>
                    </span>
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getCostAccountingList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                matterNo: '',
                vendorSelect: 0,
                vendorOption: [],
                expense: '',
                posted: false,
                startAmount: '',
                endAmount: '', 
                startDocumentDate: '',
                endDocumentDate: '',
                columns: [{
                        label: this.$t('costAccounting.matter_no'),
                        field: `matterNo`,
                    },
                    {
                        label: this.$t('costAccounting.task_expense'),
                        field: `taskExpense`,
                    },
                    {
                        label: this.$t('costAccounting.agent_client_name'),
                        field: `agentClientName`,
                    },
                    {
                        label: this.$t('costAccounting.document_date'),
                        field: `documentDate`,
                    },
                    {
                        label: this.$t('costAccounting.user_name'),
                        field: `userName`,
                    },
                    {
                        label: this.$t('costAccounting.amount_witholding_tax'),
                        field: `amountWitholdingTax`,
                    },
                    {
                        label: this.$t('costAccounting.vat_amount'),
                        field: `vatAmount`,
                    },
                    {
                        label: this.$t('costAccounting.accounting_description'),
                        field: `accountingDescription`,
                    },
                    {
                        label: 'Process',
                        field: 'process',
                        width: '10em'
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.getCostAccountingList()
        },
        methods: {
            async getSelfEmploymentInfo(id) {
                //ToDo Buraya istenen modal yazılacak.
            },
            async getDailyworkInfo(id) {
                //ToDo Buraya istenen modal yazılacak.
            },            
            async getSendAccountInfo(id) {
                //ToDo Buraya istenen modal yazılacak.
            },
            getCostAccountingList() {
                this.rows = []
                const controller = { name: 'CostAccounting' }
                const data = { pageLength: this.pageLength, pageNo: this.pageNo, matterNo: this.matterNo, vendorId: this.vendorSelect, expense: this.expense, 
                posted: this.posted, startAmount: this.startAmount, endAmount: this.endAmount, startDocumentDate: this.startDocumentDate, endDocumentDate: this.endDocumentDate }
                const payload = { controller: controller, data : data }
                this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        this.vendorOption = response.data.vendorList
                        this.vendorOption.splice(0, 0, this.$nullSelected(""))
                        response.data.lawCostAccountingList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("CostAccounting", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    