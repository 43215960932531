import useJwt from '../../auth/jwt/useJwt'

export default {
  login({ commit }, payload) {

    return new Promise((resolve, reject) => {
      useJwt.login(payload.username, payload.password)
        .then(async response => {
          // If there's user data in response
          if (response.data.resultStatus == true) {
            if (response.data.resultMessage === "PasswordNeedsToChanged") {
              window.location.href = "/ChangePassword?Id=" + response.data.url
            }
            else {
              localStorage.setItem('accessToken', response.data.data.token)
              localStorage.setItem('Name', response.data.data.name)
              localStorage.setItem('Email', response.data.data.email)
              localStorage.setItem('Surname', response.data.data.surname)
              localStorage.setItem('Role', response.data.data.role)
              localStorage.setItem('RoleId', response.data.data.roleId)
              localStorage.setItem('Title', response.data.data.title)
              localStorage.setItem('EmployeeId', response.data.data.id)
              localStorage.setItem('employeePhoto', response.data.employeePhoto)
              localStorage.setItem('DepartmentId', response.data.data.departmentId)
              commit('SET_BEARER', response.data.token)
              resolve(response)

            }
          }
          else {
            reject({ message: 'Kullanıcı Adı ve ya Şifre Yanlış.' })
          }
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      useJwt.refreshToken().then(response => {
        resolve(response)
      })
    })
  },
  getInfo(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.getInfo(payload.controller.name, payload.data).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  getList(stt, payload) {
    if (payload.data == undefined) {
      if(payload.controller.actionName == undefined){
        return new Promise((resolve, reject) => {
          useJwt.getList(payload.controller.name).then(response => {
            resolve(response)
          }).catch(error => { reject(error) })
        })
      }
      else {
        return new Promise((resolve, reject) => {
          useJwt.getListWithAction(payload.controller.name,payload.controller.actionName).then(response => {
            resolve(response)
          }).catch(error => { reject(error) })
        })
      }
    }
    else {
      return new Promise((resolve, reject) => {
        useJwt.getPostList(payload.controller.name, payload.data).then(response => {
          resolve(response)
        }).catch(error => { reject(error) })
      })
    }
  },
  delete(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.delete(payload.controller.name, payload.data).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  save(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.save(payload.controller.name, payload.data).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  download(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.download(payload.controller.name, payload.data).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  canExport(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.canExport(payload.controller.name).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  customService(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.customService(payload.controller.name, payload.controller.actionName, payload.data).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  },
  customServiceGet(stt, payload) {
    return new Promise((resolve, reject) => {
      useJwt.customServiceGet(payload.controller.name, payload.controller.actionName).then(response => {
        resolve(response)
      }).catch(error => { reject(error) })
    })
  }
}