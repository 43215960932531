
export default {
//     install(Vue){
//         Vue.prototype.$ftpClient = async function (host, port, user, pass) {
//             const FtpDeploy = require("ftp-deploy");
//             const ftpDeploy = new FtpDeploy();
//             const config = {
//                 user: user,
//                 password: pass,
//                 host: host,
//                 port: port,
//             };
//             ftpDeploy
//                 .deploy(config)
//                 .then((res) => console.log("finished:", res))
//                 .catch((err) => console.log(err));
//     }
// }
}