<template>
    <div>
      <!-- modal -->
      <b-modal id="lawMatterTypeFormModal" ref="lawMatterTypeFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawMatterType.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="law-matter-type-code-input">{{ $t('lawMatterType.code') }}</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('lawMatterType.code')" rules="required" >
                      <b-form-input id="law-matter-type-code-input" size="sm" v-model="code" maxlength="2" :state="errors.length > 0 ? false:null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-form>
            </b-col>
            <b-col class="col-lg-6">
              <b-form>
                  <label for="law-matter-type-name-input">{{ $t('lawMatterType.name') }}</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('lawMatterType.name')" rules="required" >
                      <b-form-input id="law-matter-type-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-form>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getLawMatterTypeList: {type: Function},
    },
    data() {
      return {
        id: 0,
        code: '',
        name: '',
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['lawMatterTypeFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'LawMatterType'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.code = response.data.lawMatterType.code
                this.name = response.data.lawMatterType.name
            } else {
                this.$bvModal.hide('lawMatterTypeFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, code: this.code, name: this.name}
        const controller = {name : 'LawMatterType'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$store.dispatch('moduleAuth/save',payload).then((response) => {
              if(response.data.resultStatus == true){
                  this.$bvModal.hide('lawMatterTypeFormModal')
                  this.getLawMatterTypeList()
                  this.$SaveAlert()
              } else {
                  this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  