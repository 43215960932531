import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAuth from './auth/moduleAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    dataId:'',
    listId: '',
  },
  mutations:{
    setDataId(state, payload){
      state.dataId = payload
    },
    setListId(state, payload){
      state.listId = payload
    }
  },
  actions:{
    setDataId({commit}, payload){
      commit('setDataId', payload)
    },
    setListId({commit}, payload){
      commit('setListId', payload)
    }
  },
  getters:{
    getDataId(state){
      return state.dataId
    },
    getListId(state){
      return state.listId
    }
  },
  modules:{
    app,
    appConfig,
    verticalMenu,
    moduleAuth
  },
  strict: process.env.DEV,
})
