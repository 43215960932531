<template>
    <b-card>
      <!-- form -->
      <b-form>
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="account-old-password">{{ $t('account.password_value_old') }}</label>
                <b-input-group class="input-group-merge">
                  <b-form-input size="sm" id="account-old-password" v-model="passwordValueOld" name="old-password"
                    :type="passwordFieldTypeOld" :placeholder="$t('account.password_value_old')" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" style="height: 0.5em;" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="account-new-password">{{ $t('account.new_password_value') }}</label>
                <validation-provider #default="{ errors }" name="Password" rules="required||min:3">
                  <b-input-group class="input-group-merge">
                    <b-form-input size="sm" id="account-new-password" v-model="newPasswordValue" name="new-password"
                      :type="passwordFieldTypeNew" :placeholder="$t('account.new_password_value')" maxlength="50"
                      :state="errors.length > 0 ? false : null" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" style="height: 0.5em;"  />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="account-new-password">{{ $t('account.retype_password') }}</label>
                <validation-provider #default="{ errors }" name="Password Confirm" rules="required|confirmed:Password">
                  <b-input-group class="input-group-merge">
                    <b-form-input size="sm" id="c-password" v-model="RetypePassword" :type="passwordFieldTypeRetype"
                      name="retype-password" :placeholder="$t('account.retype_password')" maxlength="50"
                      :state="errors.length > 0 ? false : null" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" style="height: 0.5em;" 
                        @click="togglePasswordRetype" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <!-- buttons -->
            <b-col cols="12">
              <b-button variant="primary" class="mt-1 mr-1" 
              :disabled="invalid" size="sm"
              @click.prevent="changePassword">
                {{ $t('account.submit') }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </validation-observer>
      </b-form>
    </b-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        passwordValueOld: '',
        newPasswordValue: '',
        RetypePassword: '',
        passwordFieldTypeOld: 'password',
        passwordFieldTypeNew: 'password',
        passwordFieldTypeRetype: 'password',
      }
    },
    computed: {
      passwordToggleIconOld() {
        return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      passwordToggleIconNew() {
        return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      passwordToggleIconRetype() {
        return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    methods: {
      togglePasswordOld() {
        this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
      },
      togglePasswordNew() {
        this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
      },
      togglePasswordRetype() {
        this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
      },
      changePassword() {
        
        const data = { id: localStorage.getItem('EmployeeId'), OldPassword: this.passwordValueOld, NewPassword: this.newPasswordValue }
        const controller = { name: 'Employee', actionName:'ChangeEmployeePassword' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.$SaveAlert()
          } else {
            this.$WarningAlert(response.data.resultMessage)
          }
        })
      }
    },
  }
  </script>
  