<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-8">
                <b-col>
                    <label for="expense-report-user-input"> {{ $t('expenseReport.user') }} </label>
                    <b-form-select id="expense-report-user-input" size="sm" v-model="userSelect" value-field="id" text-field="name" :options="userOption" class="mb-1" />
                </b-col>
                <b-col>
                    <label for="expense-report-start-date-input"> {{ $t('expenseReport.start_date') }} </label>
                    <b-form-datepicker id="expense-report-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('expenseReport.start_date')" size="sm" v-model="startDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
                <b-col>
                    <label for="expense-report-end-date-input"> {{ $t('expenseReport.end_date') }} </label>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('expenseReport.end_date')" size="sm" v-model="endDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-4">
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getExpenseReportList()" v-on:keyup.enter="getExpenseReportList()">{{ $t('expenseReport.list') }}</b-button>
                <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('expenseReport.expense_report') }}</b-button> 
                <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="expenseReportTable" ref="expenseReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'matterNo'" class="text-nowrap">
                    {{ $t('expenseReport.matter_no') }}
                </span>
                <span v-else-if="props.column.field === 'expense'" class="text-nowrap">
                    {{ $t('expenseReport.task_expense') }}
                </span>
                <span v-else-if="props.column.field === 'agentName'" class="text-nowrap">
                    {{ $t('expenseReport.agent_name') }}
                </span>
                <span v-else-if="props.column.field === 'clientName'" class="text-nowrap">
                    {{ $t('expenseReport.client_name') }}
                </span>
                <span v-else-if="props.column.field === 'matterShortName'" class="text-nowrap">
                    {{ $t('expenseReport.short_name') }}
                </span>
                <span v-else-if="props.column.field === 'description'" class="text-nowrap">
                    {{ $t('expenseReport.description') }}
                </span>
                <span v-else-if="props.column.field === 'itemDate'" class="text-nowrap">
                    {{ $t('expenseReport.item_date') }}
                </span>
                <span v-else-if="props.column.field === 'untHrs'" class="text-nowrap">
                    {{ $t('expenseReport.unt_hrs') }}
                </span>
                <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                    {{ $t('expenseReport.currency') }}
                </span>
                <span v-else-if="props.column.field === 'unitPrice'" class="text-nowrap">
                    {{ $t('expenseReport.unit_price') }}
                </span>
                <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                    {{ $t('expenseReport.amount') }}
                </span>
                <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                    {{ $t('expenseReport.user_name') }}
                </span>
                <span v-else-if="props.column.field === 'billing'" class="text-nowrap">
                    {{ $t('expenseReport.billing') }}
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getExpenseReportList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                userSelect: 0,
                userOption: [],
                startDate: null,
                endDate: null,
                columns: [{
                        label: this.$t('expenseReport.matter_no'),
                        field: `matterNo`,
                    },
                    {
                        label: this.$t('expenseReport.task_expense'),
                        field: `expense`,
                    },
                    {
                        label: this.$t('expenseReport.agent_name'),
                        field: `agentName`,
                    },
                    {
                        label: this.$t('expenseReport.client_name'),
                        field: `clientName`,
                    },
                    {
                        label: this.$t('expenseReport.short_name'),
                        field: `matterShortName`,
                    },
                    {
                        label: this.$t('expenseReport.description'),
                        field: `description`,
                    },
                    {
                        label: this.$t('expenseReport.item_date'),
                        field: `itemDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('expenseReport.unt_hrs'),
                        field: `untHrs`,
                    },
                    {
                        label: this.$t('expenseReport.currency'),
                        field: `currency`,
                    },
                    {
                        label: this.$t('expenseReport.unit_price'),
                        field: `unitPrice`,
                    },
                    {
                        label: this.$t('expenseReport.amount'),
                        field: `amount`,
                    },
                    {
                        label: this.$t('expenseReport.user_name'),
                        field: `userName`,
                    },
                    {
                        label: this.$t('expenseReport.billing'),
                        field: `billing`,
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.getExpenseReportList()
        },
        methods: {
            async getInfo(id) {
                //ToDo Buraya istenen modal yazılacak.
            },            
            getExpenseReportList() {
                this.rows = []
                const controller = { name: 'LawReport', actionName: 'GetLawExpenseReportList' }
                const data = { pageLength: this.pageLength, pageNo: this.pageNo, userId: this.userSelect, startDate: this.startDate, endDate: this.endDate }
                const payload = { controller: controller, data : data }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        this.userOption = response.data.employeeList
                        this.userOption.splice(0, 0, this.$nullSelected(""))
                        response.data.lawExpenseReportList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("ExpenseReport", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    