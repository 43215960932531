<template>
<div>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <b-col>
                <label for="lawyer-dailywork-report-start-date-input"> {{ $t('lawyerDailyworkReport.start_date') }} </label>
                <b-form-datepicker id="lawyer-dailywork-report-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawyerDailyworkReport.start_date')" size="sm" v-model="startDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
            <b-col>
                <label for="lawyer-dailywork-report-end-date-input"> {{ $t('lawyerDailyworkReport.end_date') }} </label>
                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawyerDailyworkReport.end_date')" size="sm" v-model="endDate" class="mb-1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
            <b-col>
                <b-form-radio v-model="radioClientAgent" name="some-radios" value="2" @change="getLawyerDailyworkReportList()"> {{ $t('others.client') }}</b-form-radio>
            </b-col>
            <b-col>
                <b-form-radio v-model="radioClientAgent" name="some-radios" value="1" @change="getLawyerDailyworkReportList()"> {{ $t('others.agent') }} </b-form-radio>
            </b-col>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getLawyerDailyworkReportList()" v-on:keyup.enter="getLawyerDailyworkReportList()">{{ $t('lawyerDailyworkReport.list') }}</b-button>
            <b-button style="float:right;" size="sm" variant="outline-primary" @click="getLawLawyerDailyworkReportExcel()">{{ $t('lawyerDailyworkReport.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="lawyerDailyworkReportTable" ref="lawyerDailyworkReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'lawyer'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.lawyer') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.name') }}
            </span>
            <span v-else-if="props.column.field === 'percentage'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.percentage') }}
            </span>
            <span v-else-if="props.column.field === 'usd'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.usd') }}
            </span>
            <span v-else-if="props.column.field === 'gbp'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.gbp') }}
            </span>
            <span v-else-if="props.column.field === 'eur'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.eur') }}
            </span>
            <span v-else-if="props.column.field === 'chf'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.chf') }}
            </span>
            <span v-else-if="props.column.field === 'try'" class="text-nowrap">
                {{ $t('lawyerDailyworkReport.try') }}
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

    
<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            radioActive: "2",
            startDate: '',
            endDate: '',
            radioClientAgent: 2,
            columns: [{
                    label: this.$t('lawyerDailyworkReport.lawyer'),
                    field: `lawyer`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.name'),
                    field: `name`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.percentage'),
                    field: `percentage`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.usd'),
                    field: `usd`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.gbp'),
                    field: `gbp`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.eur'),
                    field: `eur`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.chf'),
                    field: `chf`,
                },
                {
                    label: this.$t('lawyerDailyworkReport.try'),
                    field: `try`,
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getLawyerDailyworkReportList()
    },
    methods: {
        getLawyerDailyworkReportList() {
            if (this.startDate == '') {
                this.startDate = new Date()
            }
            if (this.endDate == '') {
                this.endDate = new Date()
            }
            this.rows = []
            const controller = {
                name: 'LawReport',
                actionName: 'GetLawLawyerDailyworkReport'
            }
            const data = {
                selection: this.radioClientAgent,
                startDate: this.startDate,
                endDate: this.endDate
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    response.data.lawLawyerDailyworkReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getLawLawyerDailyworkReportExcel() {
            if (this.startDate == '') {
                this.startDate = new Date()
            }
            if (this.endDate == '') {
                this.endDate = new Date()
            }
            const controller = {
                name: 'LawReport',
                actionName: 'GetLawLawyerDailyworkReportListAsExcel'
            }
            const data = {
                selection: this.radioClientAgent,
                startDate: this.startDate,
                endDate: this.endDate
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.filename, response.data.base64, ".xlsx")
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    },
}
</script>

    
<style lang="scss">
.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
