<template>
    <div>
      <!-- modal -->
      <b-modal id="lawDecisionFormModal" ref="lawDecisionFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawDecision.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col class="col-lg-12">
              <b-form>
                  <label for="law-decision-name-input">{{ $t('lawDecision.name') }}</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name=" $t('lawDecision.name')" rules="required" >
                      <b-form-input id="law-decision-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
              <b-col class="col-lg-3">
                <b-form>
                  <label for="law-decision-is-first-instance-input">{{ $t('lawDecision.is_first_instance') }}</label>
                  <b-form-checkbox name="law-decision-is-first-instance-input" id="law-decision-is-first-instance-input" class="custom-control-success" v-model="isFirstInstance" switch>
                      <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                      </span>
                  </b-form-checkbox>
                </b-form>
              </b-col>
              <b-col class="col-lg-3">
                  <b-form>
                    <label for="law-decision-is-regional-input">{{ $t('lawDecision.is_regional') }}</label>
                    <b-form-checkbox name="law-decision-is-regional-input" id="law-decision-is-regional-input" class="custom-control-success" v-model="isRegional" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                  </b-form>
              </b-col>
              <b-col class="col-lg-3">
                  <b-form>
                    <label for="law-decision-is-supreme-input">{{ $t('lawDecision.is_supreme') }}</label>
                    <b-form-checkbox name="law-decision-is-supreme-input" id="law-decision-is-supreme-input" class="custom-control-success" v-model="isSupreme" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                  </b-form>
              </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getLawDecisionList: {type: Function},
    },
    data() {
      return {
        id: 0,
        name: '',
        isFirstInstance: false,
        isRegional: false,
        isSupreme: false
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['lawDecisionFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'LawDecision'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.name = response.data.lawDecision.name
                this.isFirstInstance = response.data.lawDecision.isFirstInstance
                this.isRegional = response.data.lawDecision.isRegional
                this.isSupreme = response.data.lawDecision.isSupreme
            } else {
                this.$bvModal.hide('lawDecisionFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, name: this.name, isFirstInstance: this.isFirstInstance, isRegional: this.isRegional, isSupreme: this.isSupreme}
        const controller = {name : 'LawDecision'}
        const payload = {data: data, controller: controller}
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$store.dispatch('moduleAuth/save',payload).then((response) => {
              if(response.data.resultStatus == true){
                  this.$bvModal.hide('lawDecisionFormModal')
                  this.getLawDecisionList()
                  this.$SaveAlert()
              } else {
                  this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  