<template>
<div>
    <!-- modal -->
    <b-modal id="matterDecisionAdjustmentsFormModal" ref="matterDecisionAdjustmentsFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterDecisionAdjustments.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-decision-adjustments-date-input">{{ $t('matterDecisionAdjustments.date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterDecisionAdjustments.date')" size="sm" v-model="decisionAdjustmentDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-decision-adjustments-implementation-date-input">{{ $t('matterDecisionAdjustments.implementation_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterDecisionAdjustments.implementation_date')" size="sm" v-model="implementationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <label for="matter-decision-adjustments-approval-input">{{ $t('matterDecisionAdjustments.is_decision_adjustment_approval') }}</label>
                <b-form-checkbox class="custom-control-success" name="matter-decision-adjustments-approval-input" id="matter-decision-adjustments-approval-input" v-model="isDecisionAdjustmentApproval" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterDecisionAdjustmentsList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            lawMatterId:0,
            decisionAdjustmentDate: null,
            implementationDate: null,
            isDecisionAdjustmentApproval: false
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['matterDecisionAdjustmentsFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterDecisionAdjustments'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.decisionAdjustmentDate = response.data.lawMatterDecisionAdjustments.decisionAdjustmentDate
                    this.implementationDate = response.data.lawMatterDecisionAdjustments.implementationDate
                    this.isDecisionAdjustmentApproval = response.data.lawMatterDecisionAdjustments.isDecisionAdjustmentApproval
                } else {
                    this.$bvModal.hide('matterDecisionAdjustmentsFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                decisionAdjustmentDate: this.decisionAdjustmentDate,
                implementationDate: this.implementationDate,
                isDecisionAdjustmentApproval: this.isDecisionAdjustmentApproval,
                lawMatterId : this.lawMatterId
            }
            const controller = {
                name: 'LawMatterDecisionAdjustments'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterDecisionAdjustmentsFormModal')
                    this.getMatterDecisionAdjustmentsList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
