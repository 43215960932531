<template>
<div>
    <b-card>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-customs-registration-conclusion-input">{{ $t('matterCustomsRegistration.conclusion') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-customs-registration-conclusion-input" size="sm" v-model="conclusionSelect" value-field="id" text-field="name" :options="conclusionOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-number-input">{{ $t('matterCustomsRegistration.number') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-customs-registration-number-input" v-model="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-first-application-date-input">{{ $t('matterCustomsRegistration.first_application_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.first_application_date')" size="sm" v-model="firstApplicationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-application-number-input">{{ $t('matterCustomsRegistration.application_number') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-customs-registration-application-number-input" v-model="applicationNumber" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-last-application-date-input">{{ $t('matterCustomsRegistration.last_application_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.last_application_date')" size="sm" v-model="lastApplicationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-response-date-input">{{ $t('matterCustomsRegistration.response_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.response_date')" size="sm" v-model="responseDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-customs-registration-expiration-date-input">{{ $t('matterCustomsRegistration.expiration_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.expiration_date')" size="sm" v-model="expirationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-customs-registration-trademark-input">{{ $t('matterCustomsRegistration.trademark') }}</label>
                    <b-form-group>
                        <b-form-textarea id="matter-customs-registration-trademark-input" :placeholder=" $t('matterCustomsRegistration.trademark')" v-model="trademark" rows="3" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-button style="float: right;" variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('matterCustomsRegistration.save')  }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            conclusionSelect: 0,
            conclusionOption: [],
            number: '',
            firstApplicationDate: null,
            applicationNumber: '',
            lastApplicationDate: null,
            responseDate: null,
            expirationDate: null,
            trademark: ''
        }
    },
    methods: {
        showInfo(id) {
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterCustomsRegistration'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.conclusionSelect = response.data.lawMatterCustomsRegistration.conclusionId
                    this.conclusionOption = response.data.conclusionList
                    this.number = response.data.lawMatterCustomsRegistration.number
                    this.firstApplicationDate = response.data.lawMatterCustomsRegistration.firstApplicationDate
                    this.applicationNumber = response.data.lawMatterCustomsRegistration.applicationNumber
                    this.lastApplicationDate = response.data.lawMatterCustomsRegistration.lastApplicationDate
                    this.responseDate = response.data.lawMatterCustomsRegistration.responseDate
                    this.expirationDate = response.data.lawMatterCustomsRegistration.expirationDate
                    this.trademark = response.data.lawMatterCustomsRegistration.trademark
                    this.conclusionOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                conclusionId: this.conclusionSelect,
                number: this.number,
                firstApplicationDate: this.firstApplicationDate,
                applicationNumber: this.applicationNumber,
                lastApplicationDate: this.lastApplicationDate,
                responseDate: this.responseDate,
                expirationDate: this.expirationDate,
                trademark: this.trademark,
                lawMatterId: this.lawMatterId
            }
            const controller = {
                name: 'LawMatterCustomsRegistration'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
