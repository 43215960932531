<template>
<div>
    <clients-form :getClientsList="getClientsList" ref="clientsFormModal"></clients-form>
    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-8">
            <div class="mr-1">
                <label for="client-name-input">{{ $t('others.search_name') }}</label>
                <b-form-input size="sm" v-model="name" v-on:keyup.enter="getClientsList()" :placeholder="$t('clients.name')" type="text" class="d-inline-block mb-2" />
            </div>

            <div class="mr-1">
                <label for="client-country-input">{{ $t('lawClient.country') }}</label>
                <b-form-select id="client-country-input" v-model="countrySelect" size="sm" value-field="id" text-field="name" :options="countryOption" class="mb-2" />
            </div>

            <b-form-checkbox class="custom-control-success mr-3" id="client-is-agent-input" v-model="isAgent"> {{ $t('clients.is_agent') }}</b-form-checkbox>
            <b-form-checkbox class="custom-control-success" id="client-is-owner-input" v-model="isOwner"> {{ $t('clients.is_owner') }}</b-form-checkbox>
        </b-col>
        <b-col class="d-flex justify-content-end col-lg-4">
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getClientsList()" v-on:keyup.enter="getClientsList()">{{ $t('clients.list') }}</b-button>
            <b-button style="float:right;" class="mr-1" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('clients.add') }}</b-button>
            <b-button style="float:right;"  size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('lawyerDailyworkReport.excel') }}</b-button>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="clientsTable" ref="clientsTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('clients.name') }}
            </span>
            <span v-else-if="props.column.field === 'clientAccountingList'" class="text-nowrap">
                {{ $t('clients.account') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Client Transactions Name -->
            <span v-if="props.column.field === 'name'" class="text-nowrap">
                <b-col>
                    <b-row>
                        <tr v-if="props.row.isAgent === true && props.row.isOwner === false">
                            <span style="font-size: smaller" class="text-nowrap"><b>{{ props.row.client }}</b>
                                <b-badge style="color:black;" pill variant="info">{{$t('clients.agent')}}</b-badge>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isOwner === true && props.row.isAgent === false">
                            <span style="font-size: smaller" class="text-nowrap"><b>{{ props.row.client }}</b>
                                <b-badge style="color:black;" pill variant="success">{{$t('clients.owner')}}</b-badge>
                            </span>
                        </tr>
                        <tr v-else-if="props.row.isAgent === true && props.row.isOwner === true">
                            <span style="font-size: smaller" class="text-nowrap"><b>{{ props.row.client }}</b>
                                <b-badge style="color:black;" pill variant="info">{{$t('clients.agent')}}</b-badge>
                                <b-badge style="color:black;" pill variant="success">{{$t('clients.owner')}}</b-badge>
                            </span>
                        </tr>
                    </b-row>
                    <b-row v-if="props.row.clientAddress !== null">
                        <span>
                            <tr v-if="clientAddress.address !== null" style="font-size: smaller" class="text-nowrap">{{ props.row.clientAddress.address}}
                            </tr>
                        </span>
                    </b-row>
                </b-col>
            </span>

            <span v-else-if="props.column.field === 'clientAccountingList'">
                <b-col>
                    <b-row v-for="clientAccounting in props.row.clientAccountingList" v-bind:key="clientAccounting.code">
                        <tr v-if="clientAccounting.forAgent === true && clientAccounting.forInvoice === false">
                            <span style=" font-size: small" class="mb-05 text-nowrap">
                                {{clientAccounting.number }}
                        <tr v-if="clientAccounting.code !== ''">
                            <b-badge style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge>
                        </tr>
                        <b-badge style="color:black;" pill variant="info">{{$t('clients.agent')}}</b-badge>
            </span> <br />
            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap mb-05">{{clientAccounting.note.substr(0, 50)}}</span>
            </tr>
            <tr v-else-if="clientAccounting.forInvoice === true && clientAccounting.forAgent === false">
                <span style="font-size: small" class="mb-05 text-nowrap">
                    {{clientAccounting.number }}
            <tr v-if="clientAccounting.code !== ''">
                <b-badge style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge>
            </tr>
            <b-badge style="color:black;" pill variant="warning">{{$t('clients.invoice')}}</b-badge>
            </span> <br />
            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap mb-05">{{clientAccounting.note.substr(0, 50)}}</span>
            </tr>
            <tr v-else-if="clientAccounting.forInvoice === true && clientAccounting.forAgent === true">
                <span style=" font-size: small" class="mb-05 text-nowrap">
                    {{clientAccounting.number }}
            <tr v-if="clientAccounting.code !== ''">
                <b-badge style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge>
            </tr>
            <b-badge style="color:black;" pill variant="info">{{$t('clients.agent')}}</b-badge>
            <b-badge style="color:black;" pill variant="warning">{{$t('clients.invoice')}}</b-badge>
            </span> <br />
            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap mb-05">{{clientAccounting.note.substr(0, 50)}}</span>
            </tr>
            <tr v-else-if="clientAccounting.forInvoice === false && clientAccounting.forAgent === false">
                <span style="font-size: small" class="mb-05 text-nowrap">
                    {{clientAccounting.number }}
            <tr v-if="clientAccounting.code !== ''">
                <b-badge style="color:black;" pill variant="secondary">{{clientAccounting.code}} </b-badge>
            </tr>
            </span> <br />
            <span v-if="clientAccounting.note != null" v-b-popover.hover.top="clientAccounting.note" style="font-size: x-small;cursor: pointer;" class="text-nowrap mb-05">{{clientAccounting.note.substr(0, 50)}}</span>
            </tr>
            </b-row>
            </b-col>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title="$t('others.view')" v-b-modal.modal-top variant="info" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">

                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getClientsList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

  
<script>
export default {
    data() {
        return {
            name: "",
            isAgent: false,
            isOwner: false,
            countrySelect: 0,
            countryOption: [],
            total: 0,
            pageLength: 10,
            pageNo: 1,
            dir: false,
            clientAddress: '',
            columns: [{
                    label: this.$t('clients.name'),
                    field: `name`,
                },
                {
                    label: this.$t('clients.account'),
                    field: `clientAccountingList`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    mounted() {
        this.getClientsList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawClient'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getClientsList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getClientsList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.clientsFormModal.showInfo(id)
        },
        getClientsList() {
            if (this.isAgent == false && this.isOwner == false) {
                this.isAgent = null
                this.isOwner = null
            }
            this.rows = []
            const controller = {
                name: 'LawClient'
            }
            const data = {
                clientName: this.name,
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                isAgent: this.isAgent,
                isOwner: this.isOwner,
                countryId: this.countrySelect
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.countrySelect = this.countrySelect
                    this.countryOption = response.data.countryList
                    this.countryOption.splice(0, 0, this.$nullSelected())

                    response.data.lawClientList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawClient',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Clients", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>
  
<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

[dir] .dark-layout .card {
    background-color: #252d3f !important;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%) !important;
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    font-size: 1rem;
    position: static;
    inline-size: max-content !important;
}
</style>
