<template>
    <b-card>
      <b-form>
        <b-row>
          <b-col>
            <b-img v-b-tooltip.html :title="$t('account.change')" style="cursor: pointer;" :src="imageSrc"
              v-bind="mainProps" onclick="document.getElementById('accountGeneralFileSelect').click()" rounded="circle"
              alt="Circle image" class="d-inline-block mb-1" />
          </b-col>
          <b-col>
            <input hidden id="accountGeneralFileSelect" type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
          </b-col>
        </b-row>
        <account-form :image="image" :uploadImage="uploadImage" :accountForm="accountForm"></account-form>
      </b-form>
      <b-form class="mt-2">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" :name="$t('account.name')" rules="required">
                  <label for="account-name-input">{{  $t('account.name')  }}</label>
                  <b-form-input size="sm" v-model="name" :placeholder="$t('account.name')" name="account-name-input" />
                  <small class="text-danger">{{  errors[0]  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" :name="$t('account.surname')" rules="required">
                  <label for="account-surname-input">{{  $t('account.surname')  }}</label>
                  <b-form-input size="sm" v-model="surname" name="account-surname-input" :placeholder="$t('account.surname')" />
                  <small class="text-danger">{{  errors[0]  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider v-slot="{ errors }" :name="$t('account.title')" :rules="{ excluded:0}" >
                  <label for="account-title-input">{{  $t('account.title')  }}</label>
                  <b-form-select size="sm" v-model="titleSelect" :options="titleOption" value-field="id" name="account-title-input" text-field="name" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{  errors[0]  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                {{  $t('account.submit')  }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-card>
  </template>
  
  <script>
  export default {
    props: {
      generalData: {
        type: Object,
        default: () => { },
      },
    },
    data() {
      return {
        profileFile: null,
        name: '',
        surname: '',
        titleSelect: 0,
        titleOption: [],
        imageSrc: '',
        image: null,
        accountForm: {},
        mainProps: {
          blank: false,
          blankColor: '#777',
          width: 75,
          height: 75,
          class: 'm1',
        },
      }
    },
    beforeMount() {
      if (localStorage.getItem('employeePhoto') == "") {
        this.imageSrc = require('@/assets/images/logoUser.jpeg')
      }
      else {
        this.imageSrc = 'data:image/png;base64,' + localStorage.getItem('employeePhoto')
      }
    },
    beforeCreate() {
      const data = { id: localStorage.getItem('EmployeeId') }
      const controller = { name: 'Employee', actionName: 'GetMyAccountInfo' }
      const payload = { data: data, controller: controller }
      this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        if (response.data.resultStatus == true) {
          this.name = response.data.employee.name
          this.surname = response.data.employee.surname
          this.titleOption = response.data.titleList
          this.titleSelect = response.data.employee.titleId
          this.titleOption.splice(0, 0, this.$nullSelected("name"))
        } else {
          this.$WarningAlert(response.data.resultMessage)
        }
      })
    },
    methods: {
      uploadImage(event) {
        this.$bvModal.show('accountForm')
        var input = event.target;
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.image = e.target.result;
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      addOrUpdate() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const data = { id: localStorage.getItem('EmployeeId'), name: this.name, surname: this.surname, titleId: this.titleSelect }
            const controller = { name: 'Employee', actionName:'SaveMyAccount' }
            const payload = { data: data, controller: controller }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
              if (response.data.resultStatus == true) {
                localStorage.setItem('Name', this.name,)
                localStorage.setItem('Surname', this.surname)
                localStorage.setItem('Title', this.title)
                this.$SaveAlert()
                window.location.reload();
              } else {
                this.$WarningAlert(response.data.resultMessage)
              }
            })
          }
        })
      },
    },
  }
  </script>
  <style>
  .upload-example-cropper {
    border: solid 1px #7367f0;
    min-height: 100px;
    width: 50%;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
  
  .button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    background: #3fb37f;
    cursor: pointer;
    transition: background 0.5s;
    font-family: Open Sans, Arial;
    margin: 0 10px;
  }
  
  .button:hover {
    background: #38d890;
  }
  
  .button input {
    display: none;
  }
  </style>
  
   