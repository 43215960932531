<template>
<div>
    <b-card>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-case-number-input">{{ $t('matterEvidenceDetection.case_number') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-evidence-detection-case-number-input" v-model="caseNumber" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-court-input">{{ $t('matterEvidenceDetection.court') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-evidence-detection-court-input" size="sm" v-model="courtSelect" value-field="id" text-field="name" :options="courtOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <label for="matter-evidence-detection-is-goods-online-input">{{ $t('matterEvidenceDetection.is_good_online') }}</label>
                <b-form-checkbox class="custom-control-success" name="matter-evidence-detection-is-goods-online-input" id="matter-evidence-detection-is-goods-online-input" v-model="isGoodsOnline" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-quantity-input">{{ $t('matterEvidenceDetection.quantity') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-evidence-detection-quantity-input" v-model="quantity" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-city-input">{{ $t('matterEvidenceDetection.city') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-evidence-detection-city-input" size="sm" v-model="citySelect" value-field="id" text-field="name" :options="cityOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-state-input">{{ $t('matterEvidenceDetection.state') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-evidence-detection-state-input" size="sm" v-model="stateSelect" value-field="id" text-field="name" :options="stateOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-type-input">{{ $t('matterEvidenceDetection.type') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-evidence-detection-type-input" size="sm" v-model="typeSelect" value-field="id" text-field="name" :options="typeOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-filed-on-date-input">{{ $t('matterEvidenceDetection.filed_on_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.filed_on_date')" size="sm" v-model="filedOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-conducted-on-date-input">{{ $t('matterEvidenceDetection.conducted_on_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.conducted_on_date')" size="sm" v-model="conductedOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-c-d-letter-send-date-input">{{ $t('matterEvidenceDetection.c_d_letter_send_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.c_d_letter_send_date')" size="sm" v-model="cdLetterSendDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-report-received-date-input">{{ $t('matterEvidenceDetection.report_received_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.report_received_date')" size="sm" v-model="reportReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-objection-filed-on-date-input">{{ $t('matterEvidenceDetection.objection_filed_on_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.objection_filed_on_date')" size="sm" v-model="objectionFiledOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-c-d-letter-served-date-input">{{ $t('matterEvidenceDetection.c_d_letter_served_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.c_d_letter_served_date')" size="sm" v-model="cdLetterServedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-response-received-date-input">{{ $t('matterEvidenceDetection.response_received_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.response_received_date')" size="sm" v-model="responseReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-evidence-detection-agreed-flat-fee-input">{{ $t('matterEvidenceDetection.agreed_flat_fee') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-evidence-detection-agreed-flat-fee-input" v-model="agreedFlatFee" type="number" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-evidence-detection-goods-input">{{ $t('matterEvidenceDetection.goods') }}</label>
                    <b-form-group>
                        <b-form-textarea id="matter-evidence-detection-goods-input" :placeholder=" $t('matterEvidenceDetection.goods')" v-model="goods" rows="3" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-button style="float: right;" variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('matterEvidenceDetection.save')  }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            caseNumber: '',
            courtSelect: 0,
            courtOption: [],
            isGoodsOnline: false,
            quantity: '',
            citySelect: 0,
            cityOption: [],
            stateSelect: 0,
            stateOption: [],
            typeSelect: 0,
            typeOption: [],
            filedOnDate: null,
            conductedOnDate: null,
            cdLetterSendDate: null,
            reportReceivedDate: null,
            objectionFiledOnDate: null,
            cdLetterServedDate: null,
            responseReceivedDate: null,
            agreedFlatFee: '',
            goods: ''
        }
    },
    methods: {
        showInfo(id) {
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterEvidenceDetection'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.caseNumber = response.data.lawMatterEvidenceDetection.caseNumber
                    this.courtSelect = response.data.lawMatterEvidenceDetection.courtId
                    this.courtOption = response.data.courtList
                    this.isGoodsOnline = response.data.lawMatterEvidenceDetection.isGoodsOnline
                    this.quantity = response.data.lawMatterEvidenceDetection.quantity
                    this.citySelect = response.data.lawMatterEvidenceDetection.cityId
                    this.cityOption = response.data.cityList
                    this.stateSelect = response.data.lawMatterEvidenceDetection.stateId
                    this.stateOption = response.data.stateList
                    this.typeSelect = response.data.lawMatterEvidenceDetection.typeId
                    this.typeOption = response.data.typeList
                    this.filedOnDate = response.data.lawMatterEvidenceDetection.filedOnDate
                    this.conductedOnDate = response.data.lawMatterEvidenceDetection.conductedOnDate
                    this.cdLetterSendDate = response.data.lawMatterEvidenceDetection.cdLetterSendDate
                    this.reportReceivedDate = response.data.lawMatterEvidenceDetection.reportReceivedDate
                    this.objectionFiledOnDate = response.data.lawMatterEvidenceDetection.objectionFiledOnDate
                    this.cdLetterServedDate = response.data.lawMatterEvidenceDetection.cdLetterServedDate
                    this.responseReceivedDate = response.data.lawMatterEvidenceDetection.responseReceivedDate
                    this.agreedFlatFee = response.data.lawMatterEvidenceDetection.agreedFlatFee
                    this.goods = response.data.lawMatterEvidenceDetection.goods
                    this.courtOption.splice(0, 0, this.$nullSelected())
                    this.cityOption.splice(0, 0, this.$nullSelected())
                    this.stateOption.splice(0, 0, this.$nullSelected())
                    this.typeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                caseNumber: this.caseNumber,
                courtId: this.courtSelect,
                isGoodsOnline: this.isGoodsOnline,
                quantity: this.quantity,
                cityId: this.citySelect,
                stateId: this.stateSelect,
                typeId: this.typeSelect,
                filedOnDate: this.filedOnDate,
                conductedOnDate: this.conductedOnDate,
                cdLetterSendDate: this.cdLetterSendDate,
                reportReceivedDate: this.reportReceivedDate,
                objectionFiledOnDate: this.objectionFiledOnDate,
                cdLetterServedDate: this.cdLetterServedDate,
                responseReceivedDate: this.responseReceivedDate,
                agreedFlatFee: this.agreedFlatFee,
                goods: this.goods,
                lawMatterId : this.lawMatterId    

            }
            const controller = {
                name: 'LawMatterEvidenceDetection'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
