<template>
<div>
    <!-- modal -->
    <b-modal id="matterAppealInfoFormModal" ref="matterAppealInfoFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterAppealInfo.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-appeal-info-appeal-date-input">{{ $t('matterAppealInfo.appeal_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterAppealInfo.appeal_date')" size="sm" v-model="appealDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-appeal-info-finalization-date-input">{{ $t('matterAppealInfo.finalization_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterAppealInfo.finalization_date')" size="sm" v-model="finalizationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterAppealInfoList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId:0,
            appealDate: null,
            finalizationDate: null
        }
    },
    methods: {
        showInfo(id) {
            this.$refs['matterAppealInfoFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterAppealInfo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.appealDate = response.data.lawMatterAppealInfo.appealDate
                    this.finalizationDate = response.data.lawMatterAppealInfo.finalizationDate
                } else {
                    this.$bvModal.hide('matterAppealInfoFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                appealDate: this.appealDate,
                finalizationDate: this.finalizationDate,
                lawMatterId: this.lawMatterId
            }
            const controller = {
                name: 'LawMatterAppealInfo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterAppealInfoFormModal')
                    this.getMatterAppealInfoList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
