import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
  },
  getters: {
    windowBreakPoint: state => {

      // This should be same as tailwind. So, it stays in sync with tailwind utility classes
      if (state.windowWidth >= 1200) return 'xl'
      else if (state.windowWidth >= 992) return 'lg'
      else if (state.windowWidth >= 768) return 'md'
      else if (state.windowWidth >= 576) return 'sm'
      else return 'xs'
    },
  
    scrollbarTag: state => {
      return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
    }
  },
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_USER_INFO (state, payload) {
      // Get Data localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser
  
      for (const property of Object.keys(payload)) {
  
        if (payload[property] !== null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          state.AppActiveUser[property] = payload[property]
  
          // Update key in localStorage
          userInfo[property] = payload[property]
        }
      }
      // Store data in localStorage
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
  },
  actions: {
    updateVerticalNavMenuWidth ({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },
  
    // VxAutoSuggest
    updateStarredPage ({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },
  
    // The Navbar
    arrangeStarredPagesLimited ({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore ({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
  
    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////
  
    toggleContentOverlay ({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme ({ commit }, val) {
      commit('UPDATE_THEME', val)
    },
  
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////
  
    updateUserInfo ({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
  },
}
