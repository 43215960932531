<template>
<div>
    <b-card>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-consulting-opposite-side-input">{{ $t('matterConsulting.opposite_side') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-consulting-opposite-side-input" v-model="oppositeSide" maxlength="300" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-matter-outcome-input">{{ $t('matterConsulting.matter_outcome') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-matter-outcome-input" size="sm" v-model="matterOutcomeSelect" value-field="id" text-field="name" :options="matterOutcomeOption" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-consulting-c-d-letter-sent-date-input">{{ $t('matterConsulting.c_d_letter_sent_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterConsulting.c_d_letter_sent_date')" size="sm" v-model="cdLetterSendDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-consulting-c-d-letter-served-input">{{ $t('matterConsulting.c_d_letter_served_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterConsulting.c_d_letter_served_date')" size="sm" v-model="cdLetterServedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-button style="float: right;" variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('matterConsulting.save')  }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            cdLetterSendDate: null,
            cdLetterServedDate: null,
            oppositeSide: '',
            matterOutcomeSelect: 0,
            matterOutcomeOption: []
        }
    },
    methods: {
        showInfo(id) {
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterConsulting'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.cdLetterSendDate = response.data.lawMatterConsulting.cdLetterSendDate
                    this.cdLetterServedDate = response.data.lawMatterConsulting.cdLetterServedDate
                    this.oppositeSide = response.data.lawMatterConsulting.oppositeSide
                    this.matterOutcomeSelect = response.data.lawMatterConsulting.matterOutcomeId
                    this.matterOutcomeOption = response.data.matterOutcomeList
                    this.matterOutcomeOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                cdLetterSendDate: this.cdLetterSendDate,
                cdLetterServedDate: this.cdLetterServedDate,
                oppositeSide: this.oppositeSide,
                matterOutcomeId: this.matterOutcomeSelect,
                lawMatterId : this.lawMatterId
            }
            const controller = {
                name: 'LawMatterConsulting'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
