<template>
<div>
    <!-- modal -->
    <b-modal id="lawCourtFormModal" ref="lawCourtFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawCourt.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="law-court-name-input">{{ $t('lawCourt.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('lawCourt.name')" rules="required">
                                <b-form-input id="law-court-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>

                <b-col class="col-lg-6">
                    <b-form>
                        <label for="law-court-type-input">{{ $t('lawCourt.lawCourtType') }}</label>
                        <b-form-group>
                            <validation-provider :name=" $t('lawCourt.lawCourtType')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <b-form-select :state="errors.length > 0 ? false:null" id="law-court-type-input" size="sm" v-model="lawCourtTypeSelect" value-field="id" text-field="name" :options="lawCourtTypeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-6">
                    <b-form>
                        <label for="law-court-law-matter-case-type-input">{{ $t('lawCourt.law_matter_case_type') }}</label>
                        <b-form-group>
                            <b-form-select id="law-court-law-matter-case-type-input" size="sm" v-model="lawMatterCaseTypeSelect" value-field="id" text-field="name" :options="lawMatterCaseTypeOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawCourtList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            name: '',
            lawMatterCaseTypeOption: [],
            lawMatterCaseTypeSelect: 0,
            lawCourtTypeOption: [],
            lawCourtTypeSelect: 0,
            type: '',
        }
    },
    methods: {
        clear() {
            this.lawMatterCaseTypeSelect = 0;
            this.lawCourtTypeSelect = 0;
            this.name = '';
            this.type = 0;
        },
        showInfo(id) {
            this.$refs['lawCourtFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawCourt'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawCourtTypeOption = response.data.lawCourtTypeList
                    this.lawMatterCaseTypeOption = response.data.lawMatterCaseTypeList
                    this.name = response.data.lawCourt.name
                    if (response.data.lawCourt.lawMatterCaseTypeId > 0) {
                        this.lawMatterCaseTypeSelect = response.data.lawCourt.lawMatterCaseTypeId
                    }
                    else {
                        this.lawMatterCaseTypeSelect = 0
                    }

                    if (response.data.lawCourt.lawCourtTypeTypeId > 0) {
                        this.lawCourtTypeSelect = response.data.lawCourt.lawCourtTypeId
                    }
                    else {
                        this.lawCourtTypeSelect = 0
                    }
                    
                    this.lawCourtTypeOption.splice(0, 0, this.$nullSelected())
                    this.lawMatterCaseTypeOption.splice(0, 0, this.$nullSelected())

                } else {
                    this.$bvModal.hide('lawCourtFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                lawCourtTypeId: this.lawCourtTypeSelect,
                lawMatterCaseTypeId: this.lawMatterCaseTypeSelect
            }
            const controller = {
                name: 'LawCourt'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('lawCourtFormModal')
                            this.getLawCourtList()
                            this.$SaveAlert()
                            this.clear()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
