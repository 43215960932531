import Vue from "vue";
import CustomPlugin from "@core/custom/custom";
import FTPClientPlugin from "@core/components/ftp-client/FTPClient";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueExcelXlsx from "vue-excel-xlsx";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import excel from "vue-excel-export";
import { BAlert, BPagination, BFormGroup, BDropdownItem, BDropdown, BFormInput, BInputGroupPrepend, BFormSelect, BFormSelectOption, BRow, BCol,
  VBTooltip, BButton, BCardText, BCard, BCardBody, BLink, BImg, BCardTitle, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormRadioGroup,
  BFormRadio, BForm, BFormTextarea, BFormFile, BBadge, BTable, BFormTimepicker, BMedia, BFormDatepicker, BPopover, VBPopover, BMediaAside,
  BMediaBody, BTabs, BTab, BCardHeader, BAvatar, BFormCheckboxGroup, BFormInvalidFeedback, BFormValidFeedback, BTableSimple, BThead, BTr, BTh, BTd, 
  BTbody, BTfoot, BListGroup, BListGroupItem, BDropdownForm } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSweetalert2 from "vue-sweetalert2";
import { localize } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import i18n from "@/libs/i18n";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Cleave from "vue-cleave-component";
import Vueditor from "vueditor";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import FlipCountdown from "vue2-flip-countdown";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueApexCharts from 'vue-apexcharts'
import AwesomeMask from 'awesome-mask'
import draggable from 'vuedraggable'
import CustomDatePicker from "@core/components/CustomDatePicker.vue";


//Pages
import AccountGeneralForm from "@/@modules/Forms/Account/AccountGeneralForm.vue";
import AccountPasswordForm from "@/@modules/Forms/Account/AccountPasswordForm.vue";
import Dashboard from '@/views/pages/Dashboard/Dashboard.vue'
import MatterList from "@/@modules/Lists/Matter/MatterList.vue";
import MatterForm from "@/@modules/Forms/Matter/MatterForm.vue";
import ClientsList from "@/@modules/Lists/Clients/ClientsList.vue";
import ClientsForm from "@/@modules/Forms/Clients/ClientsForm.vue";
import LawClientList from "@/@modules/Lists/Definitions/LawClientList.vue";
import LawClientForm from "@/@modules/Forms/Definitions/LawClient/LawClientForm.vue";
import LawClientDetailForm from "@/@modules/Forms/Definitions/LawClient/LawClientSubForm/LawClientDetailForm.vue";
import LawActivityList from "@/@modules/Lists/Definitions/LawActivityList.vue";
import LawActivityForm from "@/@modules/Forms/Definitions/LawActivityForm.vue";
import LawDecisionList from "@/@modules/Lists/Definitions/LawDecisionList.vue";
import LawDecisionForm from "@/@modules/Forms/Definitions/LawDecisionForm.vue";
import LawMatterFileTypeList from "@/@modules/Lists/Definitions/LawMatterFileTypeList.vue";
import LawMatterFileTypeForm from "@/@modules/Forms/Definitions/LawMatterFileTypeForm.vue";
import LawMatterOutcomeList from "@/@modules/Lists/Definitions/LawMatterOutcomeList.vue";
import LawMatterOutcomeForm from "@/@modules/Forms/Definitions/LawMatterOutcomeForm.vue";
import LawMatterStatusList from "@/@modules/Lists/Definitions/LawMatterStatusList.vue";
import LawMatterStatusForm from "@/@modules/Forms/Definitions/LawMatterStatusForm.vue";
import LawMatterTypeList from "@/@modules/Lists/Definitions/LawMatterTypeList.vue";
import LawMatterTypeForm from "@/@modules/Forms/Definitions/LawMatterTypeForm.vue";
import LawSubjectList from "@/@modules/Lists/Definitions/LawSubjectList.vue";
import LawSubjectForm from "@/@modules/Forms/Definitions/LawSubjectForm.vue";
import MatterDocumentList from "@/@modules/Lists/Matter/MatterSubTables/MatterDocumentList.vue";
import MatterDocumentForm from "@/@modules/Forms/Matter/MatterSubForm/MatterDocumentForm.vue";
import MatterNoteList from "@/@modules/Lists/Matter/MatterSubTables/MatterNoteList.vue";
import MatterNoteForm from "@/@modules/Forms/Matter/MatterSubForm/MatterNoteForm.vue";
import MatterPlaintiffList from "@/@modules/Lists/Matter/MatterSubTables/MatterPlaintiffList.vue";
import MatterPlaintiffForm from "@/@modules/Forms/Matter/MatterSubForm/MatterPlaintiffForm.vue";
import MatterDefendantList from "@/@modules/Lists/Matter/MatterSubTables/MatterDefendantList.vue";
import MatterDefendantForm from "@/@modules/Forms/Matter/MatterSubForm/MatterDefendantForm.vue";
import MatterConsultingForm from "@/@modules/Forms/Matter/MatterSubForm/MatterConsultingForm.vue";
import MatterBudgetList from "@/@modules/Lists/Matter/MatterSubTables/MatterBudgetList.vue";
import MatterBudgetForm from "@/@modules/Forms/Matter/MatterSubForm/MatterBudgetForm.vue";
import MatterFirstInstanceCourtList from "@/@modules/Lists/Matter/MatterSubTables/MatterFirstInstanceCourtList.vue";
import MatterFirstInstanceCourtForm from "@/@modules/Forms/Matter/MatterSubForm/MatterFirstInstanceCourtForm.vue";
import MatterFinalizationList from "@/@modules/Lists/Matter/MatterSubTables/MatterFinalizationList.vue";
import MatterFinalizationForm from "@/@modules/Forms/Matter/MatterSubForm/MatterFinalizationForm.vue";
import MatterAppealInfoList from "@/@modules/Lists/Matter/MatterSubTables/MatterAppealInfoList.vue";
import MatterAppealInfoForm from "@/@modules/Forms/Matter/MatterSubForm/MatterAppealInfoForm.vue";
import MatterDecisionAdjustmentsList from "@/@modules/Lists/Matter/MatterSubTables/MatterDecisionAdjustmentsList.vue";
import MatterDecisionAdjustmentsForm from "@/@modules/Forms/Matter/MatterSubForm/MatterDecisionAdjustmentsForm.vue";
import MatterSupremeCourtList from "@/@modules/Lists/Matter/MatterSubTables/MatterSupremeCourtList.vue";
import MatterSupremeCourtForm from "@/@modules/Forms/Matter/MatterSubForm/MatterSupremeCourtForm.vue";
import MatterRegionalCourtOfAppealList from "@/@modules/Lists/Matter/MatterSubTables/MatterRegionalCourtOfAppealList.vue";
import MatterRegionalCourtOfAppealForm from "@/@modules/Forms/Matter/MatterSubForm/MatterRegionalCourtOfAppealForm.vue";
import MatterCautionList from "@/@modules/Lists/Matter/MatterSubTables/MatterCautionList.vue";
import MatterCautionForm from "@/@modules/Forms/Matter/MatterSubForm/MatterCautionForm.vue";
import MatterEvidenceDetectionForm from "@/@modules/Forms/Matter/MatterSubForm/MatterEvidenceDetectionForm.vue";
import MatterCustomsRegistrationForm from "@/@modules/Forms/Matter/MatterSubForm/MatterCustomsRegistrationForm.vue";
import MatterStopCustomsForm from "@/@modules/Forms/Matter/MatterSubForm/MatterStopCustomsForm.vue";
import MatterSmugglingForm from "@/@modules/Forms/Matter/MatterSubForm/MatterSmugglingForm.vue";
import InvoiceList from "@/@modules/Lists/Finance/InvoiceList.vue";
import InvoiceForm from "@/@modules/Forms/Finance/InvoiceForm.vue";
import InvoiceAddDailyworkForm from "@/@modules/Forms/Finance/InvoiceAddDailyworkForm.vue";
import PaymentList from "@/@modules/Lists/Finance/PaymentList.vue";
import PaymentForm from "@/@modules/Forms/Finance/PaymentForm.vue";
import DailyworkReportList from "@/@modules/Lists/Matter/MatterSubTables/Dailywork/DailyworkReportList.vue";
import DailyworkList from "@/@modules/Lists/Matter/MatterSubTables/Dailywork/DailyworkList.vue";
import DailyworkForm from "@/@modules/Forms/Matter/MatterSubForm/DailyworkForm.vue";
import AgingReportList from "@/@modules/Lists/Reports/AgingReportList.vue";
import CancelledInvoiceList from "@/@modules/Lists/Reports/CancelledInvoiceList.vue";
import CostAccountingList from "@/@modules/Lists/Reports/CostAccountingList.vue";
import CreditNotesList from "@/@modules/Lists/Reports/CreditNotesList.vue";
import ExpenseReportList from "@/@modules/Lists/Reports/ExpenseReportList.vue";
import LawyerDailyworkReportList from "@/@modules/Lists/Reports/LawyerDailyworkReportList.vue";
import MatterReportList from "@/@modules/Lists/Reports/MatterReportList.vue";
import VouchersList from "@/@modules/Lists/Reports/VouchersList.vue";
import ReminderReportList from "@/@modules/Lists/Reminder/ReminderReportList.vue";
import ReminderForm from "@/@modules/Forms/Reminder/ReminderForm.vue";
import EmployeeList from "@/@modules/Lists/Definitions/EmployeeList.vue";
import EmployeeForm from "@/@modules/Forms/Definitions/EmployeeForm.vue";
import EmployeePhotoCropperForm from "@/@modules/Forms/Definitions/EmployeeSubForm/EmployeePhotoCropperForm.vue";
import EmployeePriceList from "@/@modules/Lists/Definitions/EmployeeSubList/EmployeePriceList.vue";
import EmployeePriceForm from "@/@modules/Forms/Definitions/EmployeeSubForm/EmployeePriceForm.vue";
import LawMatterEvidenceList from "@/@modules/Lists/Matter/MatterSubTables/LawMatterEvidenceList.vue";
import LawMatterEvidenceForm from "@/@modules/Forms/Matter/MatterSubForm/LawMatterEvidenceForm.vue";
import LawCourtList from "@/@modules/Lists/Definitions/LawCourtList.vue";
import LawCourtForm from "@/@modules/Forms/Definitions/LawCourtForm.vue";
import InvoiceDetailList from "@/@modules/Lists/Finance/InvoiceDetailList.vue"
//components
Vue.component("BCardHeader", BCardHeader);
Vue.component("BCardText", BCardText);
Vue.component("VueGoodTable", VueGoodTable);
Vue.component("BPagination", BPagination);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BForm", BForm);
Vue.component("BBadge", BBadge);
Vue.component("BFormTextarea", BFormTextarea);
Vue.component("BFormInput", BFormInput);
Vue.component("BFormSelect", BFormSelect);
Vue.component("BFormSelectOption", BFormSelectOption);
Vue.component("BMedia", BMedia);
Vue.component("BAvatar", BAvatar);
Vue.component("BPopover", BPopover);
Vue.component("BTable", BTable);
Vue.component("BMediaAside", BMediaAside);
Vue.component("BMediaBody", BMediaBody);
Vue.component("BRow", BRow);
Vue.component("BCol", BCol);
Vue.component("BTabs", BTabs);
Vue.component("BTab", BTab);
Vue.component("BDropdownForm", BDropdownForm);
Vue.component("BFormFile", BFormFile);
Vue.component("BButton", BButton);
Vue.component("BInputGroup", BInputGroup);
Vue.component("BInputGroupPrepend", BInputGroupPrepend);
Vue.component("BInputGroupAppend", BInputGroupAppend);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BFormRadioGroup", BFormRadioGroup);
Vue.component("BFormRadio", BFormRadio);
Vue.component("AppCollapseItem", AppCollapseItem);
Vue.component("AppCollapse", AppCollapse);
Vue.component("BCardCode", BCardCode);
Vue.component("BCard", BCard);
Vue.component("BLink", BLink);
Vue.component("BImg", BImg);
Vue.component("VuexyLogo", VuexyLogo);
Vue.component("BCardTitle", BCardTitle);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("required", required);
Vue.component("vSelect", vSelect);
Vue.component("BFormCheckboxGroup", BFormCheckboxGroup);
Vue.component("BFormInvalidFeedback", BFormInvalidFeedback);
Vue.component("BFormValidFeedback", BFormValidFeedback);
Vue.component("BDropdownItem", BDropdownItem);
Vue.component("BDropdown", BDropdown);
Vue.component("Cleave", Cleave);
Vue.component("BCardBody", BCardBody);
Vue.component("flatPickr", flatPickr);
Vue.component("FlipCountdown", FlipCountdown);
Vue.component("BFormDatepicker", BFormDatepicker);
Vue.component("BTableSimple", BTableSimple);
Vue.component("BThead", BThead);
Vue.component("BTr", BTr);
Vue.component("BTh", BTh);
Vue.component("BTd", BTd);
Vue.component("BTbody", BTbody);
Vue.component("BTfoot", BTfoot);
Vue.component("BListGroup", BListGroup);
Vue.component("BListGroupItem", BListGroupItem);
Vue.component("BFormTimepicker",BFormTimepicker);
Vue.component("VueApexCharts",VueApexCharts);
Vue.component("draggable", draggable)
Vue.component("BAlert", BAlert)
Vue.component("CustomDatePicker", CustomDatePicker);
// Vue.component('Loading', Loading);
Vue.use(Loading);
Vue.component("FormWizard", FormWizard);
Vue.component("TabContent", TabContent);

//Pages for component
Vue.component("AccountGeneralForm",AccountGeneralForm);
Vue.component("AccountPasswordForm",AccountPasswordForm);
Vue.component("Dashboard",Dashboard);
Vue.component("MatterList",MatterList);
Vue.component("MatterForm",MatterForm);
Vue.component("ClientsList",ClientsList);
Vue.component("ClientsForm",ClientsForm);
Vue.component("LawClientList",LawClientList);
Vue.component("LawClientForm",LawClientForm);
Vue.component("LawClientDetailForm",LawClientDetailForm);
Vue.component("LawActivityList",LawActivityList);
Vue.component("LawActivityForm",LawActivityForm);
Vue.component("LawDecisionList",LawDecisionList);
Vue.component("LawDecisionForm",LawDecisionForm);
Vue.component("LawMatterFileTypeList",LawMatterFileTypeList);
Vue.component("LawMatterFileTypeForm",LawMatterFileTypeForm);
Vue.component("LawMatterOutcomeList",LawMatterOutcomeList);
Vue.component("LawMatterOutcomeForm",LawMatterOutcomeForm);
Vue.component("LawMatterStatusList",LawMatterStatusList);
Vue.component("LawMatterStatusForm",LawMatterStatusForm);
Vue.component("LawMatterTypeList",LawMatterTypeList);
Vue.component("LawMatterTypeForm",LawMatterTypeForm);
Vue.component("LawSubjectList",LawSubjectList);
Vue.component("LawSubjectForm",LawSubjectForm);
Vue.component("InvoiceList",InvoiceList);
Vue.component("InvoiceForm",InvoiceForm);
Vue.component("InvoiceAddDailyworkForm",InvoiceAddDailyworkForm);
Vue.component("MatterDocumentList",MatterDocumentList);
Vue.component("MatterDocumentForm",MatterDocumentForm);
Vue.component("MatterNoteList",MatterNoteList);
Vue.component("MatterNoteForm",MatterNoteForm);
Vue.component("MatterPlaintiffList",MatterPlaintiffList);
Vue.component("MatterPlaintiffForm",MatterPlaintiffForm);
Vue.component("MatterDefendantList",MatterDefendantList);
Vue.component("MatterDefendantForm",MatterDefendantForm);
Vue.component("MatterConsultingForm",MatterConsultingForm);
Vue.component("MatterBudgetList",MatterBudgetList);
Vue.component("MatterBudgetForm",MatterBudgetForm);
Vue.component("MatterFirstInstanceCourtList",MatterFirstInstanceCourtList);
Vue.component("MatterFirstInstanceCourtForm",MatterFirstInstanceCourtForm);
Vue.component("MatterFinalizationList",MatterFinalizationList);
Vue.component("MatterFinalizationForm",MatterFinalizationForm);
Vue.component("MatterAppealInfoList",MatterAppealInfoList);
Vue.component("MatterAppealInfoForm",MatterAppealInfoForm);
Vue.component("MatterDecisionAdjustmentsList",MatterDecisionAdjustmentsList);
Vue.component("MatterDecisionAdjustmentsForm",MatterDecisionAdjustmentsForm);
Vue.component("MatterSupremeCourtList",MatterSupremeCourtList);
Vue.component("MatterSupremeCourtForm",MatterSupremeCourtForm);
Vue.component("MatterRegionalCourtOfAppealList",MatterRegionalCourtOfAppealList);
Vue.component("MatterRegionalCourtOfAppealForm",MatterRegionalCourtOfAppealForm);
Vue.component("MatterCautionList",MatterCautionList);
Vue.component("MatterCautionForm",MatterCautionForm);
Vue.component("MatterEvidenceDetectionForm", MatterEvidenceDetectionForm);
Vue.component("MatterCustomsRegistrationForm", MatterCustomsRegistrationForm);
Vue.component("MatterStopCustomsForm", MatterStopCustomsForm);
Vue.component("MatterSmugglingForm", MatterSmugglingForm);
Vue.component("PaymentList", PaymentList);
Vue.component("PaymentForm", PaymentForm);
Vue.component("InvoiceList", InvoiceList);
Vue.component("InvoiceForm", InvoiceForm);
Vue.component("DailyworkReportList", DailyworkReportList);
Vue.component("DailyworkList", DailyworkList);
Vue.component("DailyworkForm", DailyworkForm);
Vue.component("AgingReportList", AgingReportList);
Vue.component("CancelledInvoiceList", CancelledInvoiceList);
Vue.component("CostAccountingList", CostAccountingList);
Vue.component("CreditNotesList", CreditNotesList);
Vue.component("ExpenseReportList", ExpenseReportList);
Vue.component("LawyerDailyworkReportList", LawyerDailyworkReportList);
Vue.component("MatterReportList", MatterReportList);
Vue.component("VouchersList", VouchersList);
Vue.component("ReminderReportList", ReminderReportList);
Vue.component("ReminderForm", ReminderForm);
Vue.component("EmployeeList", EmployeeList);
Vue.component("EmployeeForm", EmployeeForm);
Vue.component("EmployeePhotoCropperForm", EmployeePhotoCropperForm);
Vue.component("EmployeePriceList", EmployeePriceList);
Vue.component("EmployeePriceForm", EmployeePriceForm);
Vue.component("LawMatterEvidenceList",LawMatterEvidenceList);
Vue.component("LawMatterEvidenceForm",LawMatterEvidenceForm);
Vue.component("LawCourtList",LawCourtList);
Vue.component("LawCourtForm",LawCourtForm);
Vue.component("InvoiceDetailList",InvoiceDetailList);


import "vueditor/dist/style/vueditor.min.css";
import '@core/scss/vue/libs/vue-select.scss';
let config = {
  toolbar: [
    "removeFormat",
    "undo",
    "redo",
    "|",
    "element",
    "fontName",
    "fontSize",
    "foreColor",
    "backColor",
    "divider",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "link",
    "unLink",
    "divider",
    "subscript",
    "superscript",
    "divider",
    "justifyLeft",
    "justifyCenter",
    "justifyRight",
    "justifyFull",
    "|",
    "indent",
    "outdent",
    "insertOrderedList",
    "insertUnorderedList",
    "|",
    "picture",
    "table",
    "|",
    "code",
    "fullscreen",
    "sourceCode",
    "markdown",
  ],
  fontName: [
    { val: "arial black" },
    { val: "times new roman" },
    { val: "Courier New" },
  ],
  fontSize: [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "28px",
    "32px",
    "36px",
  ],
  uploadUrl: "",
  id: "",
  classList: [],
};
Vue.use(Vueditor, config);


//flatpickr language
if(sessionStorage.languageValue == 'tr'){
  flatpickr.localize(Turkish);
} 
// flatpickr.localize(languageForFlatPickr);

//directives
Vue.directive("b-tooltip", VBTooltip);
Vue.directive("b-popover", VBPopover);
Vue.directive("mask", AwesomeMask);
//use
Vue.use(VueSweetalert2);

import "@core/scss/vue/libs/vue-good-table.scss";
import "@core/scss/vue/libs/vue-context.scss";
import "@core/scss/vue/libs/vue-sweetalert.scss";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import router from "./router";
import App from "./App.vue";

//Manuel Created Components

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";

import app from "./store/app";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueExcelXlsx);
Vue.use(store);

// Composition API
Vue.use(VueCompositionAPI);
Vue.use(CustomPlugin);
Vue.use(FTPClientPlugin);
Vue.use(excel);

// import core styles
require("@core/scss/core.scss");

Vue.mixin(togglePasswordVisibility);

// import assets styles
require("@/assets/scss/style.scss");

//diğer dillerin eklenmesi için core/utils/validations/validations.js içinden dil eklenmesi gerekiyor.
localize("tr");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  silentTranslationWarn: true,
  render: (h) => h(App),
}).$mount("#app");
