<template>
    <div>
      <law-client-form :getLawClientList="getLawClientList" ref="lawClientFormModal"></law-client-form>
      <div class="custom-search">
        <b-row>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="law-client-client-name-input">{{ $t('lawClient.client_name') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" v-model="clientName" v-on:keyup.enter="getLawClientList()" :placeholder="$t('lawClient.client_name')" type="text" class="d-inline-block" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="law-client-client-address-input">{{ $t('lawClient.client_address') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" v-model="clientAddress" v-on:keyup.enter="getLawClientList()" :placeholder="$t('lawClient.client_address')" type="text" class="d-inline-block" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="law-client-client-accounting-input">{{ $t('lawClient.client_accounting') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" v-model="clientAccounting" v-on:keyup.enter="getLawClientList()" :placeholder="$t('lawClient.client_accounting')" type="text" class="d-inline-block" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="law-client-is-agent-input">{{ $t('lawClient.is_agent') }}</label>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-success" name="law-client-is-agent-input" id="law-client-is-agent-input" v-model="isAgent" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="law-client-is-owner-input">{{ $t('lawClient.is_owner') }}</label>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-success" name="law-client-is-owner-input" id="law-client-is-owner-input" v-model="isOwner" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-col>
            
            <b-col class="col-lg-2 custom-search d-flex justify-content-end">
              <b-form>
                <br/>
                <b-button class="mr-1" size="sm" variant="outline-primary" v-on:keyup.enter="getLawClientList()" @click="getLawClientList()">{{ $t('lawClient.list') }}</b-button>
                <b-button size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
              </b-form>
            </b-col>
        </b-row>
    </div>
      <!-- table -->
      <vue-good-table 
      id="lawClientTable"
      ref="lawClientTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize" 
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" 
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" 
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.field === 'client'" class="text-nowrap">
            {{ $t('lawClient.client') }}
          </span>
          <span v-else-if="props.column.field === 'process'" class="text-nowrap">
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.clientId)">
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getMatterList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        id: 0,
        dir: false,
        pageLength: 10,
        total: 0,
        pageNo: 1,
        columns: [
          {
            label: this.$t('lawClient.client'),
            field: `client`,
          },
          {
            label: 'Process',
            field: 'process',
            width: '10em'
          },
        ],
        rows: [],
        searchTerm: '',
        clientName: '',
        clientAddress: '',
        clientAccounting: '',
        isAgent: false,
        isOwner: false
      }
    },
    mounted() {
      this.getLawClientList()
    },
    methods: {
      async getInfo(id) {
        this.$refs.lawClientFormModal.lawClientDetailList(id)
      },
      getLawClientList() {
        this.rows = []
        if(this.isAgent == false || this.isOwner == false){
            this.isAgent = null
            this.isOwner = null
        }
        const data = { pageLength: this.pageLength, pageNo: this.pageNo, clientName: this.clientName, clientAddress: this.clientAddress, clientAccounting: this.clientAccounting,
        isAgent: this.isAgent, isOwner: this.isOwner}
        const controller = { name: 'LawClient' }
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
          if (response.data.resultStatus == true) {
            this.total = response.data.total
            response.data.lawClientList.forEach(element => {
              this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
      exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("LawClient", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
    },
  }
  </script>
  <style lang="scss" >
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  