<template>
<div>
    <!-- modal -->
    <b-modal id="matterSupremeCourtFormModal" ref="matterSupremeCourtFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterSupremeCourt.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">    
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="matter-supreme-court-decision-input"> {{ $t('matterSupremeCourt.decision') }} </label>
                        <b-form-group>
                            <validation-provider :name=" $t('matterSupremeCourt.decision')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select id="matter-supreme-court-decision-input" v-model="decisionSelect" value-field="id" text-field="name" size="sm" :options="decisionOption" :state="errors.length > 0 ? false:null"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-supreme-court-decision-no-input">{{ $t('matterSupremeCourt.decision_no') }}</label>
                        <b-form-group>
                            <b-form-input id="matter-supreme-court-decision-no-input" size="sm" v-model="decisionNo" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-supreme-court-decision-date-input">{{ $t('matterSupremeCourt.decision_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterSupremeCourt.decision_date')" size="sm" v-model="decisionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-supreme-court-case-number-input">{{ $t('matterSupremeCourt.case_number') }}</label>
                        <b-form-group>
                            <b-form-input id="matter-supreme-court-case-number-input" size="sm" v-model="caseNumber" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-supreme-court-filed-on-date-input">{{ $t('matterSupremeCourt.filed_on_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterSupremeCourt.filed_on_date')" size="sm" v-model="filedOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterSupremeCourtList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            lawMatterId:0,
            decisionSelect: 0,
            decisionOption: [],
            decisionNo: '',
            decisionDate: null,
            caseNumber: '',
            filedOnDate: null
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['matterSupremeCourtFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterSupremeCourt'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.decisionSelect = response.data.lawMatterSupremeCourt.lawDecisionId
                    this.decisionOption = response.data.lawDecisionList
                    this.decisionNo = response.data.lawMatterSupremeCourt.decisionNo
                    this.decisionDate = response.data.lawMatterSupremeCourt.decisionDate
                    this.caseNumber = response.data.lawMatterSupremeCourt.caseNumber
                    this.filedOnDate = response.data.lawMatterSupremeCourt.filedOnDate

                    this.decisionOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('matterSupremeCourtFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        id: this.id,
                        lawDecisionId: this.decisionSelect,
                        decisionNo: this.decisionNo,
                        decisionDate: this.decisionDate,
                        caseNumber: this.caseNumber,
                        filedOnDate: this.filedOnDate,
                        lawMatterId : this.lawMatterId    
                    }
                    const controller = {
                        name: 'LawMatterSupremeCourt'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('matterSupremeCourtFormModal')
                            this.getMatterSupremeCourtList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
            }})
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
