<template>
<div>
    <b-card>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-office-input">{{ $t('matterStopCustoms.office') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-office-input" v-model="office" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-decision-no-input">{{ $t('matterStopCustoms.decision_no') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-decision-no-input" v-model="decisionNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-decision-date-input">{{ $t('matterStopCustoms.decision_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.decision_date')" size="sm" v-model="decisionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-opposite-side-input">{{ $t('matterStopCustoms.opposite_side') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-opposite-side-input" v-model="oppositeSide" maxlength="300" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-sender-input">{{ $t('matterStopCustoms.sender') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-sender-input" v-model="sender" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="matter-stop-customs-bearer-input">{{ $t('matterStopCustoms.bearer') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-bearer-input" v-model="bearer" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-stop-customs-type-of-shipment-input">{{ $t('matterStopCustoms.type_of_shipment') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-stop-customs-type-of-shipment-input" size="sm" v-model="typeOfShipmentSelect" value-field="id" text-field="name" :options="typeOfShipmentOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-stop-customs-visit-date-input">{{ $t('matterStopCustoms.visit_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.visit_date')" size="sm" v-model="visitDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <b-form>
                    <label for="matter-stop-customs-goods-quantity-input">{{ $t('matterStopCustoms.goods_quantity') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="matter-stop-customs-goods-quantity-input" v-model="goodsQuantity" type="number" maxlength="100" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-3">
                <label for="matter-stop-customs-original-input">{{ $t('matterStopCustoms.original') }}</label>
                <b-form-checkbox class="custom-control-success" name="matter-stop-customs-original-input" id="matter-stop-customs-original-input" v-model="original" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-form>
                    <label for="matter-stop-customs-goods-input">{{ $t('matterStopCustoms.goods') }}</label>
                    <b-form-group>
                        <b-form-textarea id="matter-stop-customs-goods-input" :placeholder=" $t('matterStopCustoms.goods')" v-model="goods" rows="3" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-12">
                <b-button style="float: right;" variant="primary" class="mt-2 mr-1" size="sm" @click.prevent="addOrUpdate">
                    {{ $t('matterStopCustoms.save')  }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
        lawMatterId: {
            type: Number
        }
    },
    data() {
        return {
            id: 0,
            office: '',
            decisionNo: '',
            decisionDate: null,
            oppositeSide: '',
            sender: '',
            bearer: '',
            typeOfShipmentSelect: 0,
            typeOfShipmentOption: [],
            visitDate: null,
            goodsQuantity: '',
            original: false,
            goods: '',
        }
    },
    methods: {
        showInfo(id) {
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterStopCustoms'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.office = response.data.lawMatterStopCustoms.office
                    this.decisionNo = response.data.lawMatterStopCustoms.decisionNo
                    this.decisionDate = response.data.lawMatterStopCustoms.decisionDate
                    this.oppositeSide = response.data.lawMatterStopCustoms.oppositeSide
                    this.sender = response.data.lawMatterStopCustoms.sender
                    this.bearer = response.data.lawMatterStopCustoms.bearer
                    this.typeOfShipmentSelect = response.data.lawMatterStopCustoms.typeOfShipmentId
                    this.typeOfShipmentOption = response.data.typeOfShipmentList
                    this.visitDate = response.data.lawMatterStopCustoms.visitDate
                    this.goodsQuantity = response.data.lawMatterStopCustoms.goodsQuantity
                    this.original = response.data.lawMatterStopCustoms.original
                    this.goods = response.data.lawMatterStopCustoms.goods
                    this.typeOfShipmentOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                office: this.office,
                decisionNo: this.decisionNo,
                decisionDate: this.decisionDate,
                oppositeSide: this.oppositeSide,
                sender: this.sender,
                bearer: this.bearer,
                typeOfShipmentId: this.typeOfShipmentSelect,
                visitDate: this.visitDate,
                goodsQuantity: this.goodsQuantity,
                original: this.original,
                goods: this.goods,
                lawMatterId : this.lawMatterId    

            }
            const controller = {
                name: 'LawMatterStopCustoms'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
