<template>
<div>
    <!-- modal -->
    <b-modal id="matterRegionalCourtOfAppealFormModal" ref="matterRegionalCourtOfAppealFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterRegionalCourtOfAppeal.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-court-input">{{ $t('matterRegionalCourtOfAppeal.court') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-regional-court-of-appeal-court-input" size="sm" v-model="courtSelect" value-field="id" text-field="name" :options="courtOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-filed-on-date-input">{{ $t('matterRegionalCourtOfAppeal.filed_on_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterRegionalCourtOfAppeal.filed_on_date')" size="sm" v-model="filedOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-decision-input">{{ $t('matterRegionalCourtOfAppeal.decision') }}</label>
                    <b-form-group>
                        <b-form-select id="matter-regional-court-of-appeal-decision-input" size="sm" v-model="decisionSelect" value-field="id" text-field="name" :options="decisionOption" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-decision-no-input">{{ $t('matterRegionalCourtOfAppeal.decision_no') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-regional-court-of-appeal-decision-no-input" size="sm" v-model="decisionNo" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-decision-date-input">{{ $t('matterRegionalCourtOfAppeal.decision_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterRegionalCourtOfAppeal.decision_date')" size="sm" v-model="decisionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-6">
                <b-form>
                    <label for="matter-regional-court-of-appeal-case-number-input">{{ $t('matterRegionalCourtOfAppeal.case_number') }}</label>
                    <b-form-group>
                        <b-form-input id="matter-regional-court-of-appeal-case-number-input" size="sm" v-model="caseNumber" maxlength="50" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

  
<script>
export default {
    props: {
        getMatterRegionalCourtOfAppealList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId:0,
            courtSelect: 0,
            courtOption: [],
            filedOnDate: null,
            decisionSelect: 0,
            decisionOption: [],
            decisionNo: '',
            decisionDate: null,
            caseNumber: '',
            filedOnDate: null
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['matterRegionalCourtOfAppealFormModal'].show()
            this.id = id
            this.lawMatterId= lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterRegionalCourtOfAppeal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.courtSelect = response.data.lawMatterRegionalCourtOfAppeal.lawCourtId
                    this.courtOption = response.data.lawCourtList
                    this.filedOnDate = response.data.lawMatterRegionalCourtOfAppeal.filedOnDate
                    this.decisionSelect = response.data.lawMatterRegionalCourtOfAppeal.lawDecisionId
                    this.decisionOption = response.data.lawDecisionList
                    this.decisionNo = response.data.lawMatterRegionalCourtOfAppeal.decisionNo
                    this.decisionDate = response.data.lawMatterRegionalCourtOfAppeal.decisionDate
                    this.caseNumber = response.data.lawMatterRegionalCourtOfAppeal.caseNumber

                    this.courtOption.splice(0, 0, this.$nullSelected())
                    this.decisionOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('matterRegionalCourtOfAppealFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                lawCourtId: this.courtSelect,
                filedOnDate: this.filedOnDate,
                lawDecisionId: this.decisionSelect,
                decisionNo: this.decisionNo,
                decisionDate: this.decisionDate,
                caseNumber: this.caseNumber,
                lawMatterId : this.lawMatterId    
            }
            const controller = {
                name: 'LawMatterRegionalCourtOfAppeal'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('matterRegionalCourtOfAppealFormModal')
                    this.getMatterRegionalCourtOfAppealList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>
  
<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
