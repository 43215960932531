<template>
<div>
    <reminder-form :getReminderReportList="getReminderReportList" ref="reminderForm"></reminder-form>
    <clients-form ref="clientsForm"></clients-form>
    <invoice-form ref="invoiceForm"></invoice-form>

    <div class="custom-search">
        <b-row>
            <b-col class="col-md-2">
                <label for="reminder-report-date-start-input">{{ $t('others.search') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-input size="sm" v-model="searchTerm" :placeholder=" $t('others.search')" type="text" class="d-inline-block" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="reminder-report-date-start-input">{{ $t('reminder.reminder_to_date_start') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('reminder.reminder_to_date_start')" size="sm" v-model="reminderToDateStart" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <label for="reminder-report-date-end-input">{{ $t('reminder.reminder_to_date_end') }}</label>
                <div class="d-flex align-items-center">
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('reminder.reminder_to_date_end')" size="sm" v-model="reminderToDateEnd" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </div>
            </b-col>
            <b-col class="col-md-2">
                <b-form-checkbox v-model="showAllNextReminder" class="mt-1" switch inline>{{ $t('reminder.show_all_reminder') }}</b-form-checkbox>
            </b-col>
            <b-col class="col-md-4 mt-2 custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <b-button class="d-inline-block mr-1" size="sm" variant="outline-primary" v-on:keyup.enter="getReminderReportList()" @click="getReminderReportList()">{{ $t('others.list') }}</b-button>
                    <b-button class="d-inline-block mr-1" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('reminder.add') }}</b-button>
                    <b-button class="d-inline-block" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('others.excel') }}</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
    <!-- table -->
    <vue-good-table ref="reminderReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
              enabled: true,
              externalQuery: searchTerm}" :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" :pagination-options="{
              enabled: true,
              perPage:pageLength
            }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field ==='module'" class="text-nowrap">
                {{ $t('reminder.module') }}
            </span>
            <span v-else-if="props.column.field ==='recordNo'" class="text-nowrap">
                {{ $t('reminder.record_no') }}
            </span>
            <span v-else-if="props.column.field ==='note'" class="text-nowrap">
                {{ $t('reminder.note') }}
            </span>
            <span v-else-if="props.column.field ==='reminderToDate'" class="text-nowrap">
                {{ $t('reminder.reminder_to_date') }}
            </span>
            <span v-else-if="props.column.field ==='process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'recordNo'" class="text-nowrap">
                <b-badge v-if="props.row.recordNo != ''" variant="primary" style="cursor: pointer;" @click="getShowInfo(props.row.moduleId, props.row.recordId)">
                    <feather-icon icon="MousePointerIcon" class="mr-25" />
                    <span>{{ props.row.recordNo }}</span>
                </b-badge>
            </span>
            <span v-else-if="props.column.field === 'process'">
                <span>
                    <b-button v-b-tooltip.html :title=" $t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-b-tooltip.html :title=" $t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                        <feather-icon icon="XIcon" />
                    </b-button>
                </span>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}
                    </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getClientsList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            dir: false,
            columns: [{
                    label: this.$t('reminder.record_no'),
                    field: `recordNo`,
                },
                {
                    label: this.$t('reminder.module'),
                    field: `module`,
                },
                {
                    label: this.$t('reminder.note'),
                    field: `note`,
                },
                {
                    label: this.$t('reminder.record_date'),
                    field: `recordDate`,
                    formatFn: this.$formatFn,
                },
                {
                    label: this.$t('reminder.reminder_date'),
                    field: `reminderToDate`,
                    formatFn: this.$formatFn,
                },
                {
                    label: this.$t('reminder.employee'),
                    field: `employee`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
            searchTerm: '',
            reminderToDateStart: null,
            showAllNextReminder: false,
            reminderToDateEnd: new Date(),
            total: 0,
            pageNo: 1,
            pageLength: 10,
        }
    },
    watch: {
        reminderToDateStart(newValue) {
            if (newValue == '') {
                this.reminderToDateStart = null
            }
        },
        reminderToDateEnd(newValue) {
            if (newValue == '') {
                this.reminderToDateEnd = null
            }
        },
    },
    mounted() {
        this.getReminderReportList()
    },
    methods: {
        async deleted(data) {
            data = {
                id: data.id
            }
            const controller = {
                name: 'LawReminder'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.getReminderReportList()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                            this.getReminderReportList()
                        }
                    })
                }
            })
        },
        async getInfo(id) {
            this.$refs.reminderForm.showInfo(id)
        },
        getShowInfo(moduleId, recordId) {
            if(moduleId == 1)
                this.$refs.clientsForm.showInfo(recordId)
            if(moduleId == 20)
                this.$refs.invoiceForm.showInfo(recordId)
        },        
        getReminderReportList() {
            this.rows = []
            const data = {
                employeeId: localStorage.getItem("EmployeeId"),
                reminderToDateStart: this.reminderToDateStart,
                reminderToDateEnd: this.reminderToDateEnd,
                showAllNextReminder: this.showAllNextReminder,
                pageNo: this.pageNo,
                pageLength: this.pageLength
            }

            const controller = {
                name: 'LawReminder',
                actionName: 'GetLawReminderReportList'
            }

            const payload = {
                data: data,
                controller: controller
            }

            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {

                if (response.data.resultStatus == true) {
                    this.total = response.data.total
                    this.rows = response.data.lawReminderList
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            const controller = {
                name: 'LawReport',
                actionName: 'CanExport'
            }
            const data = {
                columnList: this.columns,
                DataList: this.rows
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("ReminderReport", response.data.base64, 'xlsx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
}
</script>

<style lang="scss">
.excel-css {
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem;
}

.tableSize {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
