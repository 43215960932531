<template>
<div>
    <law-client-detail-form :lawClientDetailList="lawClientDetailList" ref="lawClientDetailFormModal"></law-client-detail-form>
    <!-- modal -->
    <b-modal id="lawClientFormModal" ref="lawClientFormModal" centered size="xl" no-close-on-backdrop :title=" $t('lawClient.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <!-- table -->
        <vue-good-table id="lawClientFormTable" ref="lawClientFormTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'lawServiceType'" class="text-nowrap">
                    {{ $t('lawClient.service_type') }}
                </span>
                <span v-else-if="props.column.field === 'lawCostingType'" class="text-nowrap">
                    {{ $t('lawClient.costing_type') }}
                </span>
                <span v-else-if="props.column.field === 'code'" class="text-nowrap">
                    {{ $t('lawClient.code') }}
                </span>
                <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                    {{ $t('lawClient.name') }}
                </span>
                <span v-else-if="props.column.field === 'description'" class="text-nowrap">
                    {{ $t('lawClient.description') }}
                </span>
                <span v-else-if="props.column.field === 'vatRate'" class="text-nowrap">
                    {{ $t('lawClient.vat_rate') }}
                </span>
                <span v-else-if="props.column.field === 'accountingNo'" class="text-nowrap">
                    {{ $t('lawClient.accounting_no') }}
                </span>
                <span v-else-if="props.column.field === 'accountingDescription'" class="text-nowrap">
                    {{ $t('lawClient.accounting_description') }}
                </span>
                <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                    {{ $t('others.process') }}
                </span>
            </template>
            <!-- Column: Table Column -->
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'process'">
                    <span>
                        <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </span>
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }}
                        </span>
                        <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawClientList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            pageLength: 10,
            dir: false,
            searchTerm: '',
            columns: [{
                    label: this.$t('lawClient.service_type'),
                    field: `lawServiceType`,
                },
                {
                    label: this.$t('lawClient.costing_type'),
                    field: `lawCostingType`,
                },
                {
                    label: this.$t('lawClient.code'),
                    field: `code`,
                },
                {
                    label: this.$t('lawClient.name'),
                    field: `name`,
                },
                {
                    label: this.$t('lawClient.description'),
                    field: `description`,
                },
                {
                    label: this.$t('lawClient.vat_rate'),
                    field: `vatRate`,
                },
                {
                    label: this.$t('lawClient.accounting_no'),
                    field: `accountingNo`,
                },
                {
                    label: this.$t('lawClient.accounting_description'),
                    field: `accountingDescription`,
                },
                {
                    label: 'Process',
                    field: 'process',
                    width: '10em'
                },
            ],
            rows: [],
        }
    },
    methods: {
        async getInfo(id) {
            this.$refs.lawClientDetailFormModal.showInfo(id)
        },
        lawClientDetailList(id) {
            this.$refs['lawClientFormModal'].show()
            this.rows = []
            const data = {
                id: id
            }
            const controller = {
                name: 'LawService',
                actionName: 'GetLawServiceListByLawClientId'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.lawServiceList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
