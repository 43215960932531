<template>
    <div>
        <b-row class="align-items-center">
            <b-col class="d-flex align-items-center col-lg-6 mb-1">
               <b-col>
                    <label for="vouchers-no-input"> {{ $t('vouchers.no') }} </label>
                    <b-form-input id="vouchers-no-input" size="sm" v-model="no" v-on:keyup.enter="getVouchersList()" :placeholder="$t('vouchers.no')" type="text" class="d-inline-block mr-1" />
                </b-col>
                <b-col>
                    <label for="vouchers-start-date-input"> {{ $t('vouchers.start_date') }} </label>
                    <b-form-datepicker id="vouchers-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('vouchers.start_date')" size="sm" v-model="startDate" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
                <b-col>
                    <label for="vouchers-end-date-input"> {{ $t('vouchers.end_date') }} </label>
                    <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('vouchers.end_date')" size="sm" v-model="endDate" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                </b-col>
            </b-col>
            <b-col class="d-flex justify-content-end col-lg-6">
                <b-button class="mr-1 mt-1" style="float:right;" size="sm" variant="outline-primary" @click="getVouchersList()" v-on:keyup.enter="getVouchersList()">{{ $t('vouchers.list') }}</b-button>
                <b-button class="mt-1" size="sm" variant="outline-primary" @click="exportExcel()" >{{ $t('lawyerDailyworkReport.excel') }}</b-button>
            </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table id="vouchersTable" ref="vouchersTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }" :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" theme="my-theme">
            <div slot="emptystate">
                {{ $t('dataTable.data_not_found') }}
            </div>
            <!-- Row: Table Row -->
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'uuid'" class="text-nowrap">
                    {{ $t('vouchers.uuid') }}
                </span>
                <span v-else-if="props.column.field === 'voucherNo'" class="text-nowrap">
                    {{ $t('vouchers.no') }}
                </span>
                <span v-else-if="props.column.field === 'issueDate'" class="text-nowrap">
                    {{ $t('vouchers.issue_date') }}
                </span>
                <span v-else-if="props.column.field === 'registerNumber'" class="text-nowrap">
                    {{ $t('vouchers.register_number') }}
                </span>
                <span v-else-if="props.column.field === 'customer'" class="text-nowrap">
                    {{ $t('vouchers.customer') }}
                </span>
                <span v-else-if="props.column.field === 'voucherLine'" class="text-nowrap">
                    {{ $t('vouchers.line_desc') }}
                </span>
                <span v-else-if="props.column.field === 'price'" class="text-nowrap">
                    {{ $t('vouchers.brut_price') }}
                </span>
                <span v-else-if="props.column.field === 'kdv'" class="text-nowrap">
                    {{ $t('vouchers.vat') }}
                </span>
                <span v-else-if="props.column.field === 'witholdingTax'" class="text-nowrap">
                    {{ $t('vouchers.witholding_tax') }}
                </span>
                <span v-else-if="props.column.field === 'isSent'" class="text-nowrap">
                    {{ $t('vouchers.sent') }}
                </span>
            </template>

            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'customer'">
                    {{ props.row.customerInfo.name }} - {{ props.row.customerInfo.address }} - {{ props.row.customerInfo.country }}
                </span>
                <span v-else-if="props.column.field === 'isSent'">
                    <b-form-checkbox  disabled v-model="props.row.isSent" />
                </span>
            </template>
    
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                        <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getVouchersList()">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
    </template>
    
      
    <script>
    export default {
        data() {
            return {
                dir: false,
                pageLength: 10,
                total : 0,
                pageNo: 1,
                startDate: null,
                endDate: null,
                no:'',
                columns: [{
                        label: this.$t('vouchers.uuid'),
                        field: `uuid`,
                    },
                    {
                        label: this.$t('vouchers.no'),
                        field: `voucherNo`,
                    },
                    {
                        label: this.$t('vouchers.issue_date'),
                        field: `issueDate`,
                        formatFn: this.$formatFn
                    },
                    {
                        label: this.$t('vouchers.register_number'),
                        field: `registerNumber`,
                    },
                    {
                        label: this.$t('vouchers.customer'),
                        field: `customer`,
                    },
                    {
                        label: this.$t('vouchers.line_desc'),
                        field: `voucherLine`,
                    },
                    {
                        label: this.$t('vouchers.brut_price'),
                        field: `price`,
                    },
                    {
                        label: this.$t('vouchers.vat'),
                        field: `kdv`,
                    },
                    {
                        label: this.$t('vouchers.witholding_tax'),
                        field: `witholdingTax`,
                    },
                    {
                        label: this.$t('vouchers.sent'),
                        field: `isSent`,
                    },
                ],
                rows: [],
                searchTerm: '',
            }
        },
        mounted() {
            this.getVouchersList()
        },
        methods: {         
            getVouchersList() {
                this.rows = []
                const controller = { name: 'LawReport', actionName: 'GetLawVoucherReportList' }
                const data = { pageLength: this.pageLength, pageNo: this.pageNo, issueDateStart: this.startDate, issueDateEnd: this.endDate, voucherNo: this.no }
                const payload = { controller: controller, data : data }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.total = response.data.total
                        response.data.lawVoucherReportList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }          
                })
            },
            exportExcel() {
                const controller = { name: 'LawReport', actionName: 'CanExport'}            
                const data = { columnList : this.columns, DataList : this.rows}
                const payload = { data : data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$downloadFile("Vouchers", response.data.base64, 'xlsx')
                    }
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            }
        },
    }
    </script>
      
    <style lang="scss">
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    